<script setup lang="ts">
import { setLocale } from 'yup';
import { useToast } from 'vue-toastification';
import { getLanguage } from './libs/helpers/language';
import i18n from '@/i18n';
import yupFr from '@/locales/yup/fr';
import yupEn from '@/locales/yup/en';
import { setApiErrorHandler, toastApiErrorHandlerFactory } from '@/api';
import { onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import type { ExtendedWindow } from './@types/global';

const toast = useToast();
const store = useStore();
declare let window: ExtendedWindow;

const apiErrorHandler = toastApiErrorHandlerFactory(toast);
setApiErrorHandler(apiErrorHandler);

watch(
  () => i18n.global.locale,
  () => {
    document.getElementsByTagName('html')[0].lang = i18n.global.locale;
  },
);

watch(
  () => store.state.user,
  () => {
    window.userEmail = store.state.user?.email;
  },
);

onMounted(() => {
  // Initialize localization
  getLanguage();
  const getCurrentLocale = i18n.global.locale;
  if (getCurrentLocale) {
    if (getCurrentLocale === 'fr') {
      setLocale(yupFr);
    } else {
      setLocale(yupEn);
    }
  }
});
</script>
<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" src="./assets/scss/base.scss"></style>
<style>
.app {
  height: 100%;
}
</style>
