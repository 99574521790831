<script setup>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import Api from '@/api';

import CurrentUrgencies from './CurrentUrgencies.vue';
import ArchivedUrgenciesTable from './ArchivedUrgenciesTable.vue';

const store = useStore();

/** @type {import('vue').Ref<boolean>} */
const acknowledgedTransition = ref(false);
/** @type {import('vue').Ref<Array<import('@/store/urgencies').Urgency>>} */
const archivedUrgencies = ref([]);

/** @return {Array<import('@/store/urgencies').Urgency>} */
const acknowledgedUrgencies = ref(store.getters['urgencies/getAcknowledgedUrgencies']);

store.watch(
  () => store.state.urgencies.list,
  () => {
    acknowledgedUrgencies.value = store.getters['urgencies/getAcknowledgedUrgencies'];
  },
  { deep: true },
);

async function getArchivedUrgencies() {
  archivedUrgencies.value = await Api.urgencies.getArchivedUrgencies(store.getters.group._id);
}

async function loadDrivers() {
  await store.dispatch('drivers/loadList');
}

watch(acknowledgedUrgencies, (newUrgencies, initialUrgencies) => {
  if (initialUrgencies.length === 0 && newUrgencies.length > 0) {
    acknowledgedTransition.value = true;

    // Reset transition
    setTimeout(() => {
      acknowledgedTransition.value = false;
    }, 2000);
  }
  // Refresh archived urgencies data when an urgency is resolved
  if (newUrgencies.length < initialUrgencies.length) {
    setTimeout(() => {
      getArchivedUrgencies();
    }, 1000);
  }
});

onMounted(() => {
  loadDrivers();
  getArchivedUrgencies();
});
</script>

<template>
  <div class="urgencies-view">
    <CurrentUrgencies
      v-if="acknowledgedUrgencies.length > 0"
      :urgencies="acknowledgedUrgencies"
      :class="{ 'acknowledged--transition': acknowledgedTransition }"
    />
    <ArchivedUrgenciesTable
      :show-full-height="acknowledgedUrgencies.length === 0"
      :archived-urgencies="archivedUrgencies"
    />
  </div>
</template>

<style lang="scss">
.urgencies-view {
  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  padding: $view-standard-padding;

  .acknowledged--transition {
    animation: slide-down 1.5s ease;
  }
}
</style>
