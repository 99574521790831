import type { Device, DeviceEvent } from '@/@types/device';

/**
 * Convert an array to a fake map object.
 */
function arrToFakeMap<T>(arr: Array<T>, prop: string): Record<string, T> {
  return arr.reduce((acc: { [key: string]: T }, val) => {
    const index = val[prop as keyof T] as string;
    acc[index] = val;
    return acc;
  }, {});
}

/**
 * Convert an array to an object.
 */
function arrToObj<T>(arr: Array<T>, prop?: string): Record<string, T> {
  return arr.reduce((acc: { [key: string]: any }, val) => {
    const key = (prop ? val[prop as keyof T] : val) as string;
    acc[key] = true;

    return acc;
  }, {});
}

/**
 * Compare 2 object and return wether they have the same keys or not
 */
function compareObjectKeys(firstObject: Object, secondObject: Object): boolean {
  const firstKeys = Object.keys(firstObject).sort();
  const secondKeys = Object.keys(secondObject).sort();
  return JSON.stringify(firstKeys) === JSON.stringify(secondKeys);
}

/**
 * Filter a list to remove duplicates values based on an object key.
 * Keeps the initial order of the array
 */
function filterSameValueInList(list: Array<any>, key: string): Array<any> {
  return list.filter((v, i, a) => a.findIndex(v2 => v2[key] === v[key]) === i);
}

/**
 * Convert a stream of state mutations into a stream of full state.
 * It also merge events having the same timestamp and trip.
 */
function eventStreamToStateStream(eventStream: Array<DeviceEvent>, initState: Device): Array<DeviceEvent> {
  /** @type {import("@/store/devices").Event} */
  const state = initState || { device_id: null, ts: 0 };

  /** @type {Array<import("@/store/devices").Event>} */
  const stateStream = [];

  for (const event of eventStream) {
    Object.assign(state, event);

    const lastState = stateStream[stateStream.length - 1];

    if (
      lastState !== undefined &&
      lastState.ts === state.ts &&
      lastState.trip?.trip_id === state.trip?.trip_id &&
      lastState.trip?.start_date === state.trip?.start_date
    ) {
      Object.assign(lastState, state);
    } else {
      stateStream.push({ ...state });
    }
  }

  return stateStream;
}

export { arrToFakeMap, arrToObj, compareObjectKeys, eventStreamToStateStream, filterSameValueInList };
