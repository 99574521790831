<template>
  <div class="event-feed">
    <DropdownCheckbox v-model="feedOptions" class="event-feed__dropdown" />
    <div id="eventFeed" class="event-feed__main">
      <table class="event-feed__table table">
        <thead id="eventFeedHeader" class="event-feed__header table__head">
          <tr>
            <th class="table__head-cell">{{ $t('eventFeed.time') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.event') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.theoreticalTime') }}</th>
            <th class="table__head-cell">{{ $t('eventFeed.delay') }}</th>
          </tr>
        </thead>
        <tbody class="event-feed__table-body">
          <EventFeedTripUpdate
            v-for="(tripUpdate, index) in formattedTripUpdates"
            :key="index"
            :trip-update="tripUpdate"
          />
          <EventFeedRow
            v-for="(row, index) in eventFeedRows"
            :id="`stop_${row.stopId}_${row.type}`"
            :key="index"
            :row="row"
            :selected-date="selectedDate"
            :is-last-of-feed="index === eventFeedRows.length - 1"
            :is-first-of-feed="index === 0"
            @clickOnRow="onRowClick"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { UpdateType, trips } from '@/api';
import DropdownCheckbox from '@/components/ui/DropdownCheckbox.vue';
import { dateObjToGtfsFormat, timestampMidnight } from '@/libs/helpers/dates';

import EventFeedRow from './EventFeedRow.vue';
import EventFeedTripUpdate from './EventFeedTripUpdate.vue';
import { FeedEventType, TRIP_UPDATE_ORDER } from './EventFeedShared.js';

/** @enum {string} */
export const FeedType = {
  STOP_TIMES: 'stop-times',
  MESSAGE: 'message',
};

export default {
  name: 'EventFeed',

  components: { DropdownCheckbox, EventFeedRow, EventFeedTripUpdate },

  props: {
    deviceId: {
      required: true,
      type: [String, null, undefined],
    },

    gtfsId: {
      required: true,
      type: String,
    },

    /** @type {Vue.PropOptions<Date>} */
    selectedDate: {
      required: true,
      type: Date,
    },

    /** @type {Vue.PropOptions<Array<import('@/store/gtfs').StopTime>>} */
    stopTimes: {
      default: () => [],
      type: Array,
    },

    /** @type {Vue.PropOptions<[stopId: string]: import('@/store/gtfs').Stop>} */
    stops: {
      default: () => {},
      type: Object,
    },

    tripId: {
      required: true,
      type: String,
    },

    tripUpdates: {
      default: () => {},
      type: Object,
    },

    isTripRunning: {
      default: false,
      type: Boolean,
    },
  },

  data: () => ({
    FeedEventType,
    UpdateType,

    /** @type {Array<TripEvent>} */
    eventList: [],
    /** @type {Array<import('@/components/ui/DropdownCheckbox.vue').CheckboxOption>} */
    feedOptions: [],
    /** @type {NodeJS.Timeout} */
    updateEventFeed: null,
  }),

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /**
     * Create the events feed
     * @return {Array<EventFeedRow>} */
    eventFeedRows() {
      let firstStop = null;
      let lastStop = null;

      if (this.stopTimes.length) {
        firstStop = this.stopTimes[0];
        lastStop = this.stopTimes[this.stopTimes.length - 1];
      }
      let eventFeedRows = this.stopTimes.reduce((acc, stopTime) => {
        const eventFeedRow = /** @type {EventFeedRow} */ {};

        // Stop Id
        eventFeedRow.stopId = stopTime.stop_id;

        // Stop name
        const stop = this.stops[stopTime.stop_id];
        if (stop) {
          eventFeedRow.stopName = stop.stop_name;
        }

        // Theoretical time
        eventFeedRow.theoreticalArrivalTime = stopTime.arrival_time + this.midnight;
        eventFeedRow.theoreticalDepartureTime = stopTime.departure_time + this.midnight;

        // Type
        eventFeedRow.type = FeedEventType.ARRIVAL;
        eventFeedRow.time = eventFeedRow.theoreticalArrivalTime;
        eventFeedRow.isTheoreticalOnly = true;

        // Stop sequence
        eventFeedRow.stop_sequence = stopTime.stop_sequence;

        if (this.isOptionSelected(FeedEventType.ARRIVAL) || lastStop.stop_id === stopTime.stop_id) {
          // Push arrival first (we dont push it if case "firstStop" since we don't want "arrival" in this case)
          if (firstStop !== stopTime) {
            acc.push(eventFeedRow);
          }
        }

        // Then push a copy for departure
        const eventFeedRowClone = { ...eventFeedRow };
        eventFeedRowClone.time = eventFeedRowClone.theoreticalDepartureTime;
        eventFeedRowClone.type = FeedEventType.DEPARTURE;
        eventFeedRowClone.isTheoreticalOnly = true;
        // push departure (we dont push it if case "lastStop" since we don't want "departure" in this case)
        if (lastStop !== stopTime) {
          acc.push(eventFeedRowClone);
        }

        return acc;
      }, []);

      if (this.eventList.length > 0) {
        const stopTimesEvents = this.eventList.filter(
          event =>
            event.feed_type === FeedType.STOP_TIMES &&
            event.trip_id === this.tripId &&
            event.device_id === this.deviceId,
        );
        const messageEvents = this.eventList.filter(event => event.feed_type === FeedType.MESSAGE);

        // Real stop time
        eventFeedRows = eventFeedRows.map(currentFeedRow => {
          const event = stopTimesEvents.find(
            stEvent =>
              currentFeedRow.stop_sequence === stEvent.stop_sequence && currentFeedRow.type === stEvent.event,
          );
          if (event) {
            currentFeedRow.time = event.ts;
            currentFeedRow.isTheoreticalOnly = false;

            // Delay
            if (
              !currentFeedRow.isTheoreticalOnly &&
              currentFeedRow.theoreticalDepartureTime &&
              (currentFeedRow.type === FeedEventType.DEPARTURE ||
                (currentFeedRow.type === FeedEventType.ARRIVAL && lastStop.stop_id === event.stop_id))
            ) {
              currentFeedRow.delay = currentFeedRow.time - currentFeedRow.theoreticalDepartureTime;
            }
          }
          return currentFeedRow;
        });

        // Messages
        messageEvents.forEach(message => {
          const type = message.sender.client === 'op' ? FeedEventType.MESSAGE_SENT : FeedEventType.INBOX;
          if (this.isOptionSelected(type)) {
            const messageTime = new Date(message.date).getTime() / 1000;
            const index = eventFeedRows.findIndex(row => row.time > messageTime);
            const newRow = {
              time: messageTime,
              type,
              content: message.subject,
            };
            eventFeedRows.splice(index, 0, newRow);
          }
        });
      }
      this.$store.dispatch('tripDetailed/sendEventFeed', eventFeedRows);
      return eventFeedRows;
    },

    /** @return {Array<FormattedTripUpdate} */
    formattedTripUpdates() {
      let formattedTripUpdates = [];
      if (Object.keys(this.tripUpdates).length > 0) {
        Object.keys(this.tripUpdates).forEach(key => {
          if (key === UpdateType.COMMENT && this.tripUpdates[key] === '') {
            return;
          } else if (key === UpdateType.STOP_INFO) {
            this.tripUpdates[key].forEach(stopInfo => {
              const stopId = this.stopTimes.find(
                stopTime => stopTime.stop_sequence === stopInfo.stop_sequence,
              ).stop_id;
              formattedTripUpdates.push({
                type: key,
                content: {
                  stopName: this.stops[stopId].stop_name,
                  information: stopInfo.information,
                },
              });
            });
          } else {
            let content = this.tripUpdates[key];
            if (key === UpdateType.DO_NOT_SERVE) {
              content = [];
              this.tripUpdates[key].forEach(stopSequence => {
                const stopId = this.stopTimes.find(
                  stopTime => stopTime.stop_sequence === stopSequence,
                ).stop_id;
                content.push(this.stops[stopId].stop_name);
              });
            }
            formattedTripUpdates.push({
              type: key,
              content,
            });
          }
        });
      }
      // Trip updates must always appear in the same order
      formattedTripUpdates.sort((a, b) => TRIP_UPDATE_ORDER.get(a.type) - TRIP_UPDATE_ORDER.get(b.type));
      return formattedTripUpdates;
    },

    midnight() {
      return timestampMidnight(this.selectedDate, this.group.tz);
    },

    stopOnFocus() {
      if (this.$store.state.tripDetailed.focusedType)
        return `${this.$store.state.tripDetailed.focusedStop}_${this.$store.state.tripDetailed.focusedType}`;
      return null;
    },
  },

  watch: {
    feedOptions: {
      deep: true,
      handler() {
        localStorage.setItem('tripDetailedPage/optionsSelection', JSON.stringify(this.feedOptions));
      },
    },

    selectedDate() {
      this.getEventsList();
    },

    /** Triggered when a stop on the map is clicked */
    stopOnFocus() {
      if (this.stopOnFocus) {
        // Get element line based on stopId
        const element = document.getElementById(`stop_${this.stopOnFocus}`);
        if (!element) return;

        const headerElement = document.getElementById('eventFeedHeader');
        // Get its offset from top, remove headerElement height to have the correct result
        const topPos = element.offsetTop - headerElement.offsetHeight;
        if (topPos) {
          this.$nextTick(() => {
            // Set to parent topPosition of child
            document.getElementById('eventFeed').scrollTo({ top: topPos, behavior: 'smooth' });
            element.classList.add('active');
            setTimeout(() => {
              element.classList.remove('active');
            }, 1100);
          });
        }
      }
    },

    tripId() {
      this.getEventsList();
    },

    // Real time update on the feed only when the trip status is running
    isTripRunning() {
      if (this.isTripRunning) {
        this.startRealTimeMode();
      } else {
        clearInterval(this.updateEventFeed);
      }
    },
  },

  created() {
    this.getEventsList();
    this.getFeedOptions();

    let localStorageOptionsSelection = JSON.parse(localStorage.getItem('tripDetailedPage/optionsSelection'));
    // if selected options are saved in localStorage, apply, otherwise show all options
    if (localStorageOptionsSelection) {
      localStorageOptionsSelection = Object.values(localStorageOptionsSelection).map(option => {
        option.label = this.$t(option.value);
        return option;
      });
      this.feedOptions = localStorageOptionsSelection;
    }

    if (this.isTripRunning) {
      this.startRealTimeMode();
    }
  },

  beforeUnmount() {
    clearInterval(this.updateEventFeed);
  },

  methods: {
    async getEventsList() {
      const date = dateObjToGtfsFormat(this.selectedDate);
      this.eventList = await trips.getTripEventFeed(this.group._id, this.gtfsId, this.tripId, date);
    },

    getFeedOptions() {
      this.feedOptions = Object.values(FeedEventType).reduce((acc, option) => {
        const feedOption = {
          label: this.$t(option),
          value: option,
          selected: true,
        };
        if (feedOption.value !== FeedEventType.DEPARTURE) {
          acc.push(feedOption);
        }
        return acc;
      }, []);
    },

    /**
     * @param {import('@/components/ui/DropdownCheckbox.vue').CheckboxOption} option
     * @return {Boolean}
     */
    isOptionSelected(option) {
      if (this.feedOptions) return this.feedOptions.find(feedOption => feedOption.value === option)?.selected;
      return false;
    },

    /** Get event list every 5 seconds when trip status is running */
    startRealTimeMode() {
      this.updateEventFeed = setInterval(() => {
        this.getEventsList();
      }, 5000);
    },

    /**
     * @param {EventFeedRow} row
     */
    onRowClick(row) {
      this.$store.dispatch('tripDetailed/setFocusedByFeed', row);
    },
  },
};

/**
 * @typedef {Object} FormattedTripUpdate
 * @property {string} type
 * @property {import('@/api').Update} content
 */

/**
 * @typedef {Object} TripEvent
 * @property {Date} date
 * @property {string} device_id
 * @property {string} event
 * @property {FeedType} feed_type
 * @property {string} gtfs_id
 * @property {string} id
 * @property {string} start_date
 * @property {string} stop_id
 * @property {number} stop_sequence
 * @property {string} trip_id
 * @property {number} ts
 * @property {number} delay
 */

/**
 * @typedef {Object} EventFeedRow
 * @property {string} stopId
 * @property {string} stopName
 * @property {number} [theoreticalArrivalTime]
 * @property {number} [theoreticalDepartureTime]
 * @property {number} time
 * @property {FeedEventType} type
 * @property {Object} content
 * @property {Boolean} isTheoreticalOnly
 */
</script>

<style lang="scss" scoped>
.event-feed {
  height: 100%;
  border: 1px solid $border;
  border-radius: 8px;
  background-color: $canvas;

  &__dropdown {
    position: relative;
    z-index: $trip-detailed-dropdown-index;
    margin: 5px 0 0 5px;
  }

  &__header {
    align-items: center;
    background-color: $canvas;
    color: $text-neutral;
    box-shadow: -4px 0 10px 0 rgb(0 0 0 / 13%);
    font-size: 12px;
  }

  &__main {
    position: relative;
    overflow-y: auto;
    height: 90%;

    &::-webkit-scrollbar-track {
      background-color: $canvas;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      height: 20px;
      border-radius: 8px;
      background-color: $border;
    }
  }

  .table {
    margin: 0;

    &__head {
      border: none;
    }

    &__head-cell {
      padding: 5px 8px 8px;
    }
  }
}
</style>

<i18n locale="fr">
  {
    "arrival": "Arrivée",
    "comment": "Commentaire",
    "inbox": "Message reçu",
    "message_sent": "Message envoyé",
    "stop_time_update": "Desserte",
    "delay": "Retard",
    "schedule_relationship": "Annulation",
    "stop_info": "Informations sur les arrêts",
  }
  </i18n>

<i18n locale="en">
  {
    "arrival": "Arrival",
    "comment": "Comment",
    "inbox": "Inbox",
    "message_sent": "Message sent",
    "stop_time_update": "Servicing",
    "delay": "Delay",
    "schedule_relationship": "Cancellation",
    "stop_info": "Stop Info",
  }
  </i18n>
