<template>
  <Modal modal-class="modal-trip-modification" hide-footer :width="getModalWidth()" @close="$emit('close')">
    <template #title>
      {{ $t('tripModificationTitle') }}
    </template>
    <template #subtitle>
      {{ tripFormattedName }}
    </template>

    <template #body>
      <div class="modal-trip-modification__modal-body">
        <div v-if="loading">
          <AnimatedDots />
        </div>

        <template v-else>
          <div class="modal-trip-modification__delay">
            <label class="form-group__label modal-trip-modification__part-title" for="delay">
              {{ $t('addDelay') }}
            </label>
            <div class="modal-trip-modification__delay-input">
              <font-awesome-icon icon="fa-clock" :class="delay ? 'orange-icon' : ''" />
              <input
                id="delay"
                v-model.number="limitedDelay"
                type="number"
                :min="-999"
                placeholder="00"
                :max="999"
                class="form-group__input form-group__small-number"
                @keydown="limitDelayLength"
              />
              {{ delayWording }}
            </div>
          </div>

          <div class="modal-trip-modification__stops">
            <div class="modal-trip-modification__part-title mt-3">
              {{ $t('modifyServicing') }}
            </div>
            <div class="modal-trip-modification__stops-container">
              <StopTimesFeed
                v-model:unscheduled-stops="unscheduledStops"
                v-model:neutralized-stops="neutralizedStops"
                v-model:displaced-stops="displacedStops"
                v-model:temporary-manual-stops="temporaryManualStops"
                v-model:temporary-gtfs-stops="temporaryGtfsStops"
                :route-color="routeColor"
                :stop-times="trip.stop_times"
                :stops="stops"
                :delay="delay"
                :date="date"
                @mapFocusStop="flyTo"
              />

              <div class="modal-trip-modification__map">
                <MapboxMap
                  v-model:bounds="mapBounds"
                  border-radius="0 4px 0 0"
                  :gtfs-id="group.current_file"
                  :stops="mapStops"
                  :trips="[{ id: tripId, highlight: false }]"
                  :stops-options="{
                    stopsZones: false,
                    stopsBigMarkers: true,
                    showUnserved: true,
                    stopSelectorData: tripStopTimes,
                  }"
                  @load="onMapLoad"
                  @click:stop="cancelStop($event, null)"
                  @click:stopTooltip="cancelStop($event.event, $event.stopSequence)"
                >
                  <div v-if="isCanceledTrip" class="modal-trip-modification__disabled-trip">
                    <span class="modal-trip-modification__disabled-trip__title">
                      {{ $t('canceledTrip') }}
                    </span>
                    <Btn type="secondary" @click="isCanceledTrip = !isCanceledTrip">
                      <font-awesome-icon icon="fa-rotate-right" />
                      <span>{{ $t('restoreTrip') }}</span>
                    </Btn>
                  </div>
                </MapboxMap>
              </div>
              <div class="modal-trip-modification__cancel-all">
                <v-checkbox id="cancel-trip" v-model="isCanceledTrip" color="success" hide-details>
                  <template #label>
                    <span class="modal-trip-modification__part-title">
                      {{ $t('cancelTrip') }}
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="modal-trip-modification__footer">
        <div class="modal-trip-modification__period-select">
          <span class="period-select__label">{{ $t('from') }}</span>
          <HeaderDatepicker
            v-model:value="startDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
          <span class="period-select__label">{{ $t('to') }}</span>
          <HeaderDatepicker
            v-model:value="endDateValue"
            :disabled="disabledDates"
            :has-custom-position="false"
            without-arrows
            class="datepicker-input"
          />
        </div>
        <div class="modal-trip-modification__btn-container">
          <Btn type="secondary" @click="$emit('close')">{{ $t('cancel') }}</Btn>
          <Btn
            type="primary"
            :disabled="!hasSomethingChanged && !hasDateRangeChanged"
            @click="submitModalModify"
          >
            {{ $t('apply') }}
          </Btn>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import api, { UpdateType } from '@/api';
import MapboxMap from '@/components/map/MapboxMap.vue';
import StopTimesFeed from '@/components/common/ModalTripModification/StopTimesFeed.vue';
import Modal from '@/components/layout/Modal.vue';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import Calendar from '@/libs/calendar';
import { dateObjToGtfsFormat, dateGtfsFormatToObj } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'ModalTripModification',

  components: {
    AnimatedDots,
    Btn,
    HeaderDatepicker,
    MapboxMap,
    Modal,
    StopTimesFeed,
  },

  props: {
    date: {
      required: true,
      type: String,
    },

    gtfsId: {
      required: true,
      type: String,
    },

    tripFormattedName: {
      required: true,
      type: String,
    },

    tripId: {
      required: true,
      type: String,
    },

    tripUpdates: {
      default: () => ({}),
      type: Object,
    },
  },

  emits: ['close', 'refresh'],

  data: () => ({
    GroupRoute,
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    unscheduledStops: [],

    /** @type {Number} */
    delay: null,

    /** @type {boolean} */
    isCanceledTrip: false,

    /** @type {Array<import('@/store/gtfs').StopTime>} */
    initialUnscheduledStops: [],

    // TODO, get real data when implemented
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    neutralizedStops: [],
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    displacedStops: [],
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    temporaryManualStops: [],
    /** @type {Array<import('@/store/gtfs').StopTime>} */
    temporaryGtfsStops: [],

    /** @type {boolean} */
    loading: false,

    /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
    stops: {},

    /** @type {?import('@/store/gtfs').Trip} */
    trip: null,

    /** @type {string} */
    routeColor: null,

    /** @type {?mapboxgl.LngLatBounds} */
    mapBounds: null,

    /** @type {mapboxgl.Map} */
    map: null,

    /** @type {Date} */
    startDateValue: new Date(),

    /** @type {Date} */
    endDateValue: new Date(),

    /** @return {import('@/components/ui/Datepicker.vue').DisabledDates} */
    disabledDates: {
      minDate: null,
      maxDate: null,
    },
  }),

  computed: {
    /** @return {boolean} */
    canceled() {
      return this.tripUpdates[UpdateType.TRIP_CANCELED] ?? false;
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {boolean} */
    hasSomethingChanged() {
      return this.isModifyServingValid || this.isCanceledTrip !== this.canceled || this.checkDelayChange();
    },

    /** @return {boolean} */
    isModifyServingValid() {
      const { unscheduledStops } = this;
      if (this.trip && Object.keys(this.trip).length) {
        unscheduledStops.sort((a, b) => a.stop_sequence - b.stop_sequence);

        if (this.initialUnscheduledStops.length !== this.unscheduledStops.length) {
          return true;
        }
        return this.initialUnscheduledStops.some(
          (element, index) =>
            this.initialUnscheduledStops[index].stop_sequence !== this.unscheduledStops[index].stop_sequence,
        );
      }

      return false;
    },

    limitedDelay: {
      /** @return {Number} */
      get() {
        return this.delay;
      },

      // prevent range exceeding values from
      // others input types than keydown (eg. paste)
      /** @param {Number} val */
      set(val) {
        if (val > 999) this.delay = 999;
        else if (val < -999) this.delay = -999;
        else if (!val) this.delay = null;
        else this.delay = val;
      },
    },

    delayWording() {
      if (this.delay > 0) {
        return this.$tc('delayMinutes', this.delay);
      }
      if (this.delay < 0) {
        return this.$tc('advanceMinutes', -this.delay);
      }
      return this.$t('minutes');
    },

    mapStops() {
      const mapStopList = [];
      this.trip.stop_times.forEach(st => {
        const unserved = !!this.unscheduledStops.find(
          unservedStop => unservedStop.stop_sequence === st.stop_sequence,
        );
        mapStopList.push({ id: st.stop_id, highlight: false, unserved, stop_sequence: st.stop_sequence });
      });
      return mapStopList;
    },

    /** @return {number} */
    previousDelay() {
      return this.tripUpdates[UpdateType.DELAY] ? this.tripUpdates[UpdateType.DELAY] / 60 : null;
    },

    hasDateRangeChanged() {
      if (
        (dateObjToGtfsFormat(this.startDateValue) !== this.date ||
          dateObjToGtfsFormat(this.endDateValue) !== this.date) &&
        Object.keys(this.tripUpdates).length !== 0
      ) {
        return true;
      }
      return false;
    },

    /** @return {Array<import('@/store/gtfs').StopTime>} */
    tripStopTimes() {
      return this.trip?.stop_times ?? [];
    },
  },

  async created() {
    this.loading = true;

    if (this.date) {
      this.startDateValue = dateGtfsFormatToObj(this.date);
      this.endDateValue = dateGtfsFormatToObj(this.date);
    }

    this.isCanceledTrip = this.canceled;
    this.delay = this.previousDelay;

    const [trip, stops, routes] = await Promise.all([
      api.trips.getTripFromGtfs(this.group._id, this.gtfsId, this.tripId),
      this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId }),
      this.$store.dispatch('gtfs/getRoutesMap', {
        gtfsId: this.gtfsId,
      }),
    ]);

    this.trip = trip;
    this.stops = stops;

    // route color for design
    const route = routes[trip.route_id] || null;
    this.routeColor = route?.route_color ? `#${route.route_color}` : null;

    this.initialUnscheduledStops = this.checkUnscheduledStops(
      this.tripUpdates[UpdateType.DO_NOT_SERVE] ?? [],
    );
    this.unscheduledStops = this.initialUnscheduledStops.slice();

    // TODO, init those field based on endpoint, only uncomment it for test
    // this.unscheduledStops = [this.trip.stop_times[1]];
    // this.neutralizedStops = [this.trip.stop_times[2]];
    // this.displacedStops = [this.trip.stop_times[3]];
    // this.temporaryManualStops = [this.trip.stop_times[4]];
    // this.temporaryGtfsStops = [this.trip.stop_times[5]];

    // Define calendar inactive dates to limit date picker
    this.disabledDates = await Calendar.getInactiveDatesForATrip(this.trip.service_id, this.gtfsId);

    this.loading = false;
  },

  methods: {
    /**
     * Cancel a stop
     * @param {mapboxgl.MapLayerMouseEvent} event
     * @param {Number} [stopSequence]
     */
    cancelStop(event, stopSequence) {
      const stopId = event?.features[0]?.properties?.id;
      const stops = this.tripStopTimes.filter(stop => stop.stop_id === stopId);
      // Normal stop - no duplicate

      const currentStopSequence = stopSequence || stops[0].stop_sequence;
      this.scrollToElement(stopId + currentStopSequence);

      if (stops.length === 1) {
        if (this.unscheduledStops.some(s => s.stop_id === stops[0].stop_id)) {
          this.unscheduledStops = this.unscheduledStops.filter(s => s.stop_id !== stops[0].stop_id);
        } else {
          this.unscheduledStops.push(stops[0]);
        }
        // Stop with multiple services
        // if there is a stopSequence, it means that the tooltip has been clicked
      } else if (typeof stopSequence === 'number') {
        const currentStop = stops.find(stop => stop.stop_sequence === stopSequence);
        // Check if stop has been canceled
        const canceledStop = this.unscheduledStops.find(
          s => s.stop_id === currentStop.stop_id && s.stop_sequence === currentStop.stop_sequence,
        );
        if (canceledStop) {
          this.unscheduledStops.splice(this.unscheduledStops.indexOf(canceledStop), 1);
        } else {
          this.unscheduledStops.push(currentStop);
        }
      }
    },

    /** @param {{map: mapboxgl.Map}} event */
    onMapLoad({ map }) {
      map.once('idle', () => {
        this.map = map;
      });
    },

    /**
     * Create the initial unscheduled stopTime list based on stopTimeUpdate
     * @param {Array<number>} skippedStopsSequences
     * @return {Array<import('@/store/gtfs').StopTime>}
     */
    checkUnscheduledStops(skippedStopsSequences) {
      if (this.trip && Object.keys(this.trip).length) {
        const scheduledStops = this.tripStopTimes;
        const unscheduledStops = [];
        if (skippedStopsSequences) {
          skippedStopsSequences.forEach(stopSequence => {
            const canceledStop = scheduledStops.find(stop => stop.stop_sequence === stopSequence);
            if (canceledStop) unscheduledStops.push(canceledStop);
          });
        }
        unscheduledStops.sort((a, b) => a.stop_sequence - b.stop_sequence);
        return unscheduledStops;
      }
      return [];
    },

    limitDelayLength(e) {
      const maxLen = this.delay < 0 ? 4 : 3;
      if (this.delay && this.delay.toString().length === maxLen && e.key !== 'Backspace') {
        e.preventDefault();
      }
    },

    async submitModalModify() {
      const skippedStopTimeSeqs = /** @type {Array<number>} */ ([]);

      // Canceled stops
      this.unscheduledStops.forEach(scheduledStop => {
        skippedStopTimeSeqs.push(scheduledStop.stop_sequence);
      });

      const tripUpdates = {
        query: {
          gtfs_id: this.gtfsId,
          trip_id: this.tripId,
          start_date: dateObjToGtfsFormat(this.startDateValue),
          end_date: dateObjToGtfsFormat(this.endDateValue),
        },
        body: {
          delay: this.delay * 60, // convert to seconds
          is_canceled: this.isCanceledTrip ?? false,
          skipped_stop_time_seqs: skippedStopTimeSeqs,
          comment: this.tripUpdates[UpdateType.COMMENT] ?? null,
          stop_infos: this.tripUpdates[UpdateType.STOP_INFO] ?? [],
        },
        many: dateObjToGtfsFormat(this.startDateValue) !== dateObjToGtfsFormat(this.endDateValue),
      };

      await this.$store.dispatch('trips/updateTrip', tripUpdates);

      this.$emit('close');
      this.$emit('refresh');
    },

    /**
     * Fly action on map based on stopId
     * @param {string} stopId
     */
    flyTo(stopId) {
      const relatedStop = this.stops[stopId];
      this.map.flyTo({
        center: [relatedStop.stop_lon, relatedStop.stop_lat],
        zoom: 15,
        speed: 0.8,
      });
    },

    /** Verify if delay has changed (compared to previous delay coming from API) */
    checkDelayChange() {
      // check if delay has value (not "" or undefined or null)
      if (this.delay) {
        // then compare to previousDelay
        return this.delay !== this.previousDelay;
      }
      // else if delay has no value but previous had one before
      if (this.previousDelay) return true;
      return false;
    },

    getModalWidth() {
      return window.innerWidth * 0.8;
    },

    /**
     * Scroll to the stop in the timeline
     * @param {string} stopId
     */
    scrollToElement(stopId) {
      const element = document.getElementById(`stop-${stopId}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.modal-trip-modification {
  .modal__header {
    padding: 0;
  }

  &__btn-container {
    display: flex;
    width: 50%;

    button {
      width: 50%;
    }
  }

  &__cancel-all {
    width: 100%;
    padding: 10px 15px;
    border-top: solid 1px $border-variant;
  }

  &__delay-input {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__disabled-trip {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(235 87 87 / 80%);
    color: white;
    backdrop-filter: blur(5px);

    &__title {
      font-weight: $font-weight-semi-bold;
      font-size: 36px;
      font-family: $font-poppins;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }

  &__map {
    position: relative;
    width: 50%;
    min-width: 500px;

    // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days,  98px = footer btn, -20px bonus
    height: calc(96vh - 68px - 40px - 49px - 42px - 25px - 98px - 20px);
  }

  &__stops-container {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    border: solid 1px $border-variant;
    border-radius: 5px;
  }

  &__part-title {
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
  }

  &__period-select {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  // utilites
  .margin-auto {
    margin: auto;
  }

  .orange-icon {
    color: $warn;
  }

  .btn-display-on-hover {
    opacity: 0;
  }

  .modal__body {
    overflow-y: auto;
    margin-bottom: 0;
  }
}
</style>

<i18n locale="fr">
{
  "apply": "Appliquer les modifications",
  "tripModificationTitle": "Modification de la course",
  "minutes": "minutes",
  "advanceMinutes": "minute d'avance | minutes d'avance",
  "delayMinutes": "minute de retard | minutes de retard",
  "addDelay": "Indiquer un retard",
  "cancelTrip": "Annuler la course",
  "modifyServicing": "Modifier la desserte",
  "restore": "Restaurer",
  "canceledTrip": "Course annulée",
  "restoreTrip": "Restaurer la course",
  "neutralized": "Neutralisé",
  "from": "Du",
  "to": "au"
}
</i18n>

<i18n locale="en">
{
  "apply": "Apply changes",
  "tripModificationTitle": "Trip modification",
  "minutes": "minutes",
  "advanceMinutes": "minute early | minutes early",
  "delayMinutes": "minute late | minutes late",
  "addDelay": "Apply a delay",
  "cancelTrip": "Cancel trip",
  "modifyServicing": "Modify service",
  "restore": "Restore",
  "canceledTrip": "canceled trip",
  "restoreTrip": "Restore trip",
  "neutralized": "Neutralized",
  "from": "From",
  "to": "to"
}
</i18n>
