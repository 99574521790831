<template>
  <div id="mapdiv" ref="mapdiv" :style="{ borderRadius: borderRadius }">
    <MapboxTrips
      v-if="mapLoaded"
      :gtfs-id="gtfsId"
      :trips="trips"
      :map="map"
      @click="onMapClick($event, 'trip')"
      @mouseenter="$emit('mouseenter:trip', $event)"
      @mouseleave="$emit('mouseleave:trip', $event)"
      @isLoaded="tripsLoaded = true"
    />

    <MapboxStops
      v-if="tripsLoaded"
      :gtfs-id="gtfsId"
      :options="cStopsOptions"
      :stops="stops"
      :display-tooltip="displayTooltip"
      :map="map"
      @click="onMapClick($event, 'stop')"
      @mouseenter="onStopMouseEnter($event)"
      @mouseleave="onStopMouseLeave($event)"
      @tooltipToggleStop="onMapClick($event, 'stopTooltip')"
      @update:bounds="$emit('update:bounds', $event)"
      @isLoaded="stopsLoaded = true"
    />

    <slot v-if="stopsLoaded" :map="map" :on-map-click="onMapClick" />
  </div>
</template>

<script>
import mapboxgl, { LngLatBounds, NavigationControl } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import merge from 'deepmerge';
import MapboxTrips from '@/components/map/MapboxTrips.vue';
import MapboxStops from '@/components/map/MapboxStops.vue';

/** @type {StopsOptions} */
const DEFAULT_STOPS_OPTIONS = {
  stationsMarkers: true,
  stopsMarkers: true,
  stopsZones: false,
  stopsBigMarkers: false,
};

mapboxgl.accessToken =
  'pk.eyJ1IjoicHlzYWUiLCJhIjoiY2s0Y2hrYTlxMG50ODNra2R6ZGVudTR5aiJ9.sccZsmomeJ-zdW21vHcSYQ';

export default {
  name: 'MapboxMap',

  components: {
    MapboxStops,
    MapboxTrips,
  },

  props: {
    borderRadius: {
      type: String,
      default: '0',
    },

    gtfsId: {
      type: String,
      required: true,
    },

    /** @type {import('vue').Prop<Array<MapStop>>} */
    stops: {
      type: Array,
      required: true,
    },

    /** @type {import('vue').Prop<StopsOptions>} */
    stopsOptions: {
      type: Object,
      default: () => /** @type {StopsOptions} */ ({}),
    },

    /** @type {import('vue').Prop<Array<MapTrip>>} */
    trips: {
      type: Array,
      required: true,
    },
    /** @type {import('vue').Prop<mapboxgl.LngLatBoundsLike | null>} */
    bounds: {
      type: LngLatBounds,
      default: null,
    },

    /** @type {import('vue').Prop<?[number, number]>} */
    // @ts-ignore TS2322: compatibility between `Array` and `[number, number]`
    center: {
      type: Array,
      default: null,
    },
    displayTooltip: {
      type: Boolean,
      default: false,
    },
    fullScreenOption: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'mouseenter:trip',
    'mouseleave:trip',
    'mouseenter:stop',
    'mouseleave:stop',
    'update:bounds',
    'load',
    'click',
    'click:trip',
    'click:stop',
    'click:device',
    'click:stopTooltip',
  ],

  data: () => ({
    mapLoaded: false,
    tripsLoaded: false,
    stopsLoaded: false,
    language: null,
  }),
  computed: {
    /**
     * Merged options with defaults.
     * @return {StopsOptions}
     */
    cStopsOptions() {
      return merge(DEFAULT_STOPS_OPTIONS, this.stopsOptions);
    },
    mapLoadedCenterBounds() {
      return [this.mapLoaded, this.center, this.bounds];
    },
  },

  watch: {
    mapLoadedCenterBounds: {
      immediate: true,
      deep: true,
      handler([mapLoaded, center, bounds]) {
        if (mapLoaded) {
          this.updateMapPosition(center, bounds);
        }
      },
    },
    '$i18n.locale': function i18nLocale() {
      if (this.map) {
        this.map.setStyle(this.language.setLanguage(this.map.getStyle(), this.$i18n.locale));
      }
    },
  },

  created() {
    /** @type {mapboxgl.Map} */
    this.map = null;
    /** @type {?NodeJS.Timeout} */
    this.debouncedEventTimer = null;
  },

  async mounted() {
    this.language = new MapboxLanguage({ defaultLanguage: this.$i18n.locale });

    // Init map
    const map = new mapboxgl.Map({
      container: /** @type {HTMLElement} */ (this.$refs.mapdiv),
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [0, 0],
      zoom: 13,
      localFontFamily: "'Poppins', Arial, sans-serif",
      projection: {
        name: 'mercator',
        center: [0, 30],
        parallels: [30, 30],
      },
    });

    const nav = new NavigationControl({ showCompass: false });
    if (this.fullScreenOption) {
      map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
    }

    map.addControl(nav, 'top-right');

    map.addControl(this.language);

    const mapPromise = new Promise(resolve => {
      map.on('load', () => {
        resolve();
      });
    });

    await mapPromise;

    this.map = map;
    this.updateMapPosition();
    this.$emit('load', { map });

    this.mapLoaded = true;

    // handle click on map to deselect a device
    this.map.on('click', e => {
      if (!e.features) {
        this.onMapClick(e);
      }
    });
  },

  methods: {
    /**
     * Uses a debounce to prevent sending multiple events from different layers.
     * @param {mapboxgl.MapLayerMouseEvent} event
     * @param {string} [type]
     */
    onMapClick(event, type) {
      clearTimeout(this.debouncedEventTimer);

      // Capture `feature` here because it seems to
      // disappear in timeout function
      const feature = (event.features || [])[0];

      this.debouncedEventTimer = setTimeout(
        (event, type) => {
          const eventName = `click${type ? `:${type}` : ''}`;

          // Re-set `feature` in event data
          if (feature) {
            event.features = [feature];
          }

          this.$emit(eventName, event);
        },
        100,
        event,
        type,
      );
    },
    updateMapPosition(center = this.center, bounds = this.bounds) {
      if (center) {
        if (this.map.getZoom() < 9) {
          this.map.setZoom(14);
        }
        this.map.panTo(center);
      } else if (bounds) {
        this.map.fitBounds(bounds, {
          padding: 40,
          animate: false,
        });
      }
    },
    onStopMouseEnter(event) {
      this.map.getCanvasContainer().style.cursor = 'pointer';
      this.$emit('mouseenter:stop', event);
    },

    onStopMouseLeave(event) {
      this.map.getCanvasContainer().style.cursor = '';
      this.$emit('mouseleave:stop', event);
    },
  },
};

/**
 * @typedef {Object} MapTrip
 * @property {string} id
 * @property {import('./MapboxTrips.vue').HighlightType} highlight
 */

/**
 * @typedef {Object} MapStop
 * @property {string} id
 * @property {boolean} highlight
 * @property {boolean} [unserved]
 */

/**
 * @typedef {Object} StopsOptions
 * @property {boolean} stopsMarkers
 * @property {boolean} stopsZones
 * @property {boolean} stationsMarkers
 * @property {boolean} stopsBigMarkers
 * @property {boolean} showUnserved
 * @property {Array<import('@/store/gtfs').StopTime>} [stopSelectorData]
 */
</script>

<style lang="scss">
#mapdiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Hides info button and logo on mapbox
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner,
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  display: none !important;
}

.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
  box-shadow: none !important;
}

.mapboxgl-ctrl-group button {
  border: 1px solid $border-variant !important;
  border-radius: 5px !important;
  background-color: $canvas;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: rgb(255 255 255 / 80%);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin-bottom: 25px;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/up-right-and-down-left-from-center.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: url('../../assets/img/icons/down-left-and-up-right-to-center.svg');
}
</style>
