<script setup>
import cloneDeep from 'clone-deep';
import { dateGtfsFormatToObj, timestampFormatHHMM, timestampMidnight } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import AddTemporaryStop from '@/components/common/ModalTripModification/AddTemporaryStop.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

/** @enum {string} */
const StopActionType = {
  DISPLACE: 'displace',
  CANCEL: 'cancel',
  DELETE: 'delete',
};

const props = defineProps({
  routeColor: {
    default: '#00b871', // $primary-light
    type: String,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>}} */
  stopTimes: {
    type: Array,
    required: true,
  },
  /** @type {import('vue').Prop<{[stopId: string]: import('@/store/gtfs').Stop}>} */
  stops: {
    type: Object,
    required: true,
  },
  delay: {
    type: Number,
    default: null,
  },
  date: {
    required: true,
    type: String,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>}} */
  unscheduledStops: {
    type: Array,
    required: true,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>} */
  neutralizedStops: {
    default: () => [],
    type: Array,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>} */
  displacedStops: {
    default: () => [],
    type: Array,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>} */
  temporaryManualStops: {
    default: () => [],
    type: Array,
  },
  /** @type {import('vue').Prop<Array<import('@/store/gtfs').StopTime>>} */
  temporaryGtfsStops: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits([
  'mapFocusStop',
  'update:unscheduledStops',
  'update:neutralizedStops',
  'update:displacedStops',
  'update:temporaryManualStops',
  'update:temporaryGtfsStops',
]);

/** @type {import('vue').Ref<Array<FeedStopTimes>>} */
const feedStopTimes = ref([]);

/** @return {import('@/store').Group} */
const group = computed(() => {
  return store.getters.group;
});

/** @return {number} */
const midnight = computed(() => {
  const date = dateGtfsFormatToObj(props.date);
  return timestampMidnight(date, group.value.tz);
});

watch(
  [
    props.unscheduledStops,
    props.neutralizedStops,
    props.displacedStops,
    props.temporaryGtfsStops,
    props.temporaryManualStops,
  ],
  () => {
    updateFeedStopTime();
  },
  { deep: true },
);

onMounted(() => {
  feedStopTimes.value = cloneDeep(props.stopTimes);
  updateFeedStopTime();
});

/**
 * Update stopList on click on a row (also trigger emit to update in Modal & trigger fly on map)
 * @param {FeedStopTimes} feedStopTime
 * @param {StopActionType} action
 */
function updateStopList(feedStopTime, action) {
  if (!feedStopTime.isNeutralized) {
    const relatedStopTime = props.stopTimes.find(
      st => st.stop_id === feedStopTime.stop_id && st.stop_sequence == feedStopTime.stop_sequence,
    );

    switch (action) {
      case StopActionType.DISPLACE: {
        // TODO: not implemented yet
        break;
      }
      case StopActionType.CANCEL: {
        feedStopTime.isDisabled = !feedStopTime.isDisabled;
        let disabledList = props.unscheduledStops;
        if (feedStopTime.isDisabled) {
          disabledList.push(relatedStopTime);
        } else {
          const stopInUnscheduledList = props.unscheduledStops.find(
            s => s.stop_id === feedStopTime.stop_id && s.stop_sequence === feedStopTime.stop_sequence,
          );
          disabledList.splice(props.unscheduledStops.indexOf(stopInUnscheduledList), 1);
        }
        emit('update:unscheduledStops', disabledList);
        break;
      }
      case StopActionType.DELETE: {
        // TODO: not implemented yet
        break;
      }
      default:
        break;
    }

    emit('mapFocusStop', feedStopTime.stop_id);
  }
}
/**
 * @param {string} stopId
 * @return {string}
 */
function getStopName(stopId) {
  return (props.stops[stopId] || {}).stop_name;
}

/**
 * Get required time & format it to display
 * @param {import('@/store/gtfs').StopTime} stopTime
 */
function getStopTimeHour(stopTime) {
  let time = stopTime.departure_time;
  // get arrival time instead of departure if we are on last stop
  if (stopTime.stop_sequence === props.stopTimes.length) time = stopTime.arrival_time;

  const dateTs = (midnight.value + withDelay(time)) * 1000;
  return timestampFormatHHMM(dateTs / 1000, { tz: group.value.tz });
}
/**
 * Take a time and apply delay if exist
 * @param {number} time
 * @return {number}
 * */
function withDelay(time) {
  if (props.delay) {
    return time + props.delay * 60;
  }
  return time;
}

function updateFeedStopTime() {
  feedStopTimes.value.map(st => {
    st.isDisabled = props.unscheduledStops.some(
      s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence,
    );
    st.isNeutralized = props.neutralizedStops.some(
      s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence,
    );
    st.isDisplaced = props.displacedStops.some(
      s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence,
    );
    st.isTemporaryManual = props.temporaryManualStops.some(
      s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence,
    );
    st.isTemporaryGtfs = props.temporaryGtfsStops.some(
      s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence,
    );

    st.isRegular =
      !st.isDisabled && !st.isNeutralized && !st.isDisplaced && !st.isTemporaryManual && !st.isTemporaryGtfs;
    return st;
  });
}

/**
 * @param {FeedStopTimes} st
 * @param {boolean} isStopName
 * @return {string}
 */
function getStopColor(st, isStopName) {
  if (st.isNeutralized) {
    return '#eb5757'; //$danger
  } else if (st.isDisabled || st.isDisplaced || st.isTemporaryManual || st.isTemporaryGtfs) {
    return '#F99C49'; //$warn
  } else {
    return isStopName ? '#333' : '#F5F5F5'; //$text-dark or $background
  }
}

/**
 * @param {FeedStopTimes} st
 * @return {string}
 */
function getIconColor(st) {
  if (st.isNeutralized || st.isDisabled || st.isDisplaced || st.isTemporaryManual || st.isTemporaryGtfs) {
    return '#FBFBFB'; //$text-light
  } else {
    return '#333'; //$text-dark
  }
}

/**
 * @param {FeedStopTimes} st
 * @return {string}
 */
function getIcon(st) {
  if (st.isNeutralized || st.isDisabled) {
    return 'fa:fas fa-times';
  } else if (st.isDisplaced) {
    return '$deviateWhite';
  } else {
    return 'fa:fas fa-map-marker-alt';
  }
}

/**
 * @typedef {Object} AdditionalDataStopTimes
 * @property {boolean?} isNeutralized
 * @property {boolean?} isDisabled
 * @property {boolean?} isDisplaced
 * @property {boolean?} isTemporaryManual
 * @property {boolean?} isTemporaryGtfs
 * @property {boolean?} isRegular
 */

/**
 *  @typedef {import('@/store/gtfs').StopTime & AdditionalDataStopTimes} FeedStopTimes
 */
</script>
<template>
  <div class="modal-trip-modification__table">
    <v-timeline density="compact" side="end" truncate-line="both" :line-color="routeColor">
      <v-timeline-item
        v-for="(st, index) in feedStopTimes"
        :key="st.stop_id"
        class="mb-2 modal-trip-modification__table__timeline-line-feed"
        :class="
          st.isTemporaryGtfs || st.isTemporaryManual
            ? `modal-trip-modification__table__timeline-line-feed__squared`
            : ``
        "
        :dot-color="getStopColor(st, false)"
        :icon="getIcon(st)"
        :icon-color="getIconColor(st)"
        size="small"
      >
        <div class="modal-trip-modification__table__row">
          <div
            :id="`stop-${st.stop_id + st.stop_sequence}`"
            class="modal-trip-modification__table__row__stop"
            :class="st.isNeutralized ? `stop-neutralized` : ``"
          >
            <AddTemporaryStop v-if="index === 0" :stop="st" :is-before-first-stop="true" />
            <AddTemporaryStop :stop="st" />

            <router-link
              :title="getStopName(st.stop_id) || '-'"
              class="ml-2 modal-trip-modification__table__row__stop-name"
              :class="st.isNeutralized || st.isDisabled ? 'stop-name-deactivated' : ''"
              :style="{ color: getStopColor(st, true), fontWeight: st.isRegular ? '500' : '600' }"
              :to="{
                name: GroupRoute.STOP_DETAILED,
                params: { groupId: group._id, stopId: st.stop_id },
              }"
              target="_blank"
            >
              {{ getStopName(st.stop_id) || '-' }}
            </router-link>
            <div class="modal-trip-modification__table__right-part">
              <span v-if="st.isNeutralized" class="modal-trip-modification__table__neutralized">
                {{ $t('neutralized') }}
              </span>
              <div v-else class="modal-trip-modification__table__actions">
                <!-- <v-btn
                  v-if="!st.isDisabled && !st.isNeutralized && !st.isTemporaryGtfs"
                  class="modal-trip-modification__table__cancel margin-auto"
                  :class="st.isNeutralized ? `` : `btn-display-on-hover`"
                  size="x-small"
                  elevation="0"
                  prepend-icon="$deviateBlack"
                  @click="updateStopList(st, StopActionType.DISPLACE)"
                >
                  {{ $t('displace') }}
                </v-btn> -->
                <v-btn
                  v-if="!st.isNeutralized && !st.isTemporaryGtfs && !st.isTemporaryManual"
                  class="modal-trip-modification__table__cancel margin-auto"
                  :class="st.isNeutralized ? `` : `btn-display-on-hover`"
                  size="x-small"
                  elevation="0"
                  :prepend-icon="st.isDisabled ? 'fas fa-redo' : 'fas fa-times'"
                  @click="updateStopList(st, StopActionType.CANCEL)"
                >
                  {{ st.isDisabled ? $t('restore') : $t('cancel') }}
                </v-btn>
                <!-- <v-btn
                  v-if="st.isTemporaryGtfs || st.isTemporaryManual"
                  class="modal-trip-modification__table__delete margin-auto btn-display-on-hover"
                  size="x-small"
                  elevation="0"
                  prepend-icon="fas fa-trash"
                  @click="updateStopList(st, StopActionType.DELETE)"
                >
                  {{ $t('delete') }}
                </v-btn> -->
              </div>

              <div class="margin-auto" :class="delay && !st.isNeutralized ? 'orange-icon' : ''">
                {{ getStopTimeHour(st) }}
              </div>
            </div>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<style lang="scss">
.modal-trip-modification__table {
  overflow-y: auto;
  width: 50%;
  height: calc(
    96vh - 68px - 40px - 49px - 42px - 25px - 98px - 30px
  ); // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days, 98px = footer btn, -30px bonus

  margin-bottom: 10px;
  padding: 10px;

  /* Hide scrollbar on every bowsers */
  -ms-overflow-style: none;
  scrollbar-width: none;

  .v-timeline {
    margin: 10px 0;
  }

  &__actions {
    display: flex;
    gap: 8px;

    button {
      cursor: pointer;
    }
  }

  &__timeline-line-feed {
    .v-timeline-item__body {
      width: 100%;
    }

    &:hover {
      .add-temporary-stop__btn {
        display: inline-grid;
      }
    }

    &__squared {
      .v-timeline-divider__dot,
      .v-timeline-divider__inner-dot {
        border-radius: 4px;
      }

      .v-timeline-divider__dot {
        width: 26px;
        height: 26px;
        transform: rotate(-45deg);

        i {
          transform: rotate(45deg);
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__cancel {
    border: 1px solid $border-variant;
    border-radius: 5px;
    background: $canvas;
    color: $text-dark-variant;
    font-size: 12px;
  }

  &__delete {
    border-radius: 5px;
    background-color: $danger;
    color: $text-light;
    font-size: 12px;

    i {
      font-size: 12px;
    }

    &:hover {
      background-color: $danger-dark;
    }
  }

  &__neutralized {
    font-weight: $font-weight-semi-bold;
  }

  &__row {
    display: flex;

    &:hover {
      .modal-trip-modification__table__row__stop:not(.stop-neutralized) {
        background-color: $background-variant;
      }

      .btn-display-on-hover {
        opacity: 1;
      }
    }

    &__stop {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-left: -25px;
      padding: 18px 12px;
      border-radius: 10px;
    }

    &__stop-name {
      @include multi-line-ellipsis($font-size: 14px, $line-height: 21px, $lines-to-show: 1);
    }
  }

  &__right-part {
    display: flex;
    flex-shrink: 0;
    gap: 10px;
  }

  .stop-name-deactivated {
    text-decoration: line-through;
  }

  // vuetify timeline "override"
  .v-timeline-divider__before,
  .v-timeline-divider__after {
    border-right: solid 1px black !important;
    border-left: solid 1px black !important;
  }

  // border is baddly implemented in default vuetify css
  .v-timeline-divider__dot {
    border: 1px solid $text-dark;
  }

  .v-timeline-divider__dot--size-small .v-timeline-divider__inner-dot {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .modal-trip-modification__table {
    max-width: calc(100% - 500px);
  }
}
</style>

<i18n locale="fr">
  {
    "neutralized": "Arrêt neutralisé",
    "displace": "Déplacer"
  }
  </i18n>

<i18n locale="en">
  {
    "neutralized": "Stop neutralized",
    "displace": "Displace"
  }
  </i18n>
