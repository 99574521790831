<script setup>
import { dateGtfsFormatToObj, dateObjToGtfsFormat, timestampToGtfsFormat } from '@/libs/helpers/dates';
import { GroupBy } from '@/libs/reports';
import { GroupRoute } from '@/libs/routing';
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Api, { stats as ApiStats } from '@/api';
import { DelayState } from '@/store/devices';

import BarChartCard from './BarChartCard.vue';
import DashboardHeader from './DashboardHeader.vue';
import DonutChartCard from './DonutChartCard.vue';
import FeaturesCard from './FeaturesCard.vue';
import MapCard from './MapCard.vue';
import MetricCard from './MetricCard.vue';

const { t, d } = useI18n({});
const router = useRouter();
const store = useStore();

/** @type {import('vue').Ref<String | null>} */
const gtfsId = ref();
/** @type {import('vue').Ref<String | null>} */
const gtfsName = ref();
/** @type {import('vue').Ref<Boolean | null>} */
const hasGtfs = ref(true);
/** @type {import('vue').Ref<Array<Number>>} */
const historyDates = ref([]);
/** @type {import('vue').Ref<Boolean | null>} */
const isGtfsLoading = ref(true);
/** @type {import('vue').Ref<Number | null>} */
const tripListLength = ref();

/** @returns {Date} */
const dateOfTheDay = computed(() => {
  return new Date();
});

// Start and end dates used for all api calls
/** @returns {String} */
const from = computed(() => dateObjToGtfsFormat(new Date(new Date(historyDates.value[0]))));
/** @returns {String} */
const to = computed(() => dateObjToGtfsFormat(new Date(historyDates.value[historyDates.value.length - 1])));

/** @returns {String} */
const categories = computed(() => {
  return historyDates.value.map(ts => d(ts, 'dateShort'));
});

/** @return {import('@/store').Group} */
const group = computed(() => {
  return store.getters.group;
});

onMounted(() => {
  adaptSize();
  window.addEventListener('resize', adaptSize);
  loadDutyList();
  getGtfs();
  updateHistoryDates();
});

onUnmounted(() => {
  window.removeEventListener('resize', adaptSize);
});

watch(
  historyDates,
  () => {
    if (historyDates.value.length > 0) {
      updateStatsDailyPassengerCounts();
      updateStatsDailyPunctuality();
      updateStatsTripTracking();
      updateStatsTripsKm();
    }
  },
  { immediate: true, deep: true },
);

watch(
  group,
  () => {
    tripListLength.value = null;
    loadDutyList();
    getGtfs();
    updateHistoryDates();
  },
  { deep: true },
);

watch(gtfsId, () => {
  getLengthOfTripList();
});

async function getGtfsName() {
  const list = await Api.gtfs.getGtfs(store.getters.group.group_id);
  gtfsName.value = list.find(gtfs => gtfs._id === gtfsId.value).name;
}

async function getGtfs() {
  isGtfsLoading.value = true;
  gtfsId.value = await store.dispatch('gtfs/getGtfsAt', {
    ts: new Date().getTime() / 1000,
  });
  hasGtfs.value = !!gtfsId.value;
  if (gtfsId.value) {
    getGtfsName();
  }
  isGtfsLoading.value = false;
}

async function getLengthOfTripList() {
  const tripList = await store.dispatch('gtfs/getTripsMap', { gtfsId: gtfsId.value });
  tripListLength.value = tripList ? Object.keys(tripList).length : null;
}

function redirect(name, query) {
  router.push({
    name,
    query,
  });
}

function updateHistoryDates() {
  const dates = [];

  for (let i = 6; i >= 0; i -= 1) {
    dates.push(
      new Date(
        dateOfTheDay.value.getFullYear(),
        dateOfTheDay.value.getMonth(),
        dateOfTheDay.value.getDate() - i,
      ).getTime(),
    );
  }

  historyDates.value = dates;
}

// #region Responsive
/** @type {import('vue').Ref<Number | null>} */
const vueInnerWidth = ref();

const cols = computed(() => {
  if (vueInnerWidth.value > 920) return [4, 4, 4, 8, 4, 4, 8];
  else return [6, 6, 6, 6, 6, 6, 12];
});

function adaptSize() {
  const leftNavBarWidth = localStorage.getItem('settings.op.navLeftClosed') === 'true' ? 84 : 180;
  vueInnerWidth.value = window.innerWidth - leftNavBarWidth;
}
// #endregion

// #region Duty list
async function loadDutyList() {
  await store.dispatch('activityLog/loadEntries', dateObjToGtfsFormat(dateOfTheDay.value));
}

/** @returns {Number | String} */
const dutyListLength = computed(() => {
  return hasGtfs.value ? store.state.activityLog.entries.length : '-';
});
// #endregion

// #region Live punctuality
/** @type {import('vue').Ref<Array<import('@/components/ui/DonutChart.vue').DonutData>>} */
const livePunctuality = ref([]);
/** @type {import('vue').Ref<Boolean>} */
const firstLivePunctualityLoading = ref(true);

/**
 * Get all online devices.
 * @return {{[deviceId: string]: import('@/store/devices').Device}}
 */
const onlineDevices = computed(() => {
  return store.getters['devices/onlineDevices'];
});

/** @returns {{string: number}}*/
const devicesStatus = computed(() => {
  return Object.values(onlineDevices.value).reduce(
    (acc, device) => {
      if (device.delay != null) {
        const delayState = store.getters['devices/getDelayState'](device.delay);
        acc[delayState] += 1;
      }

      if (device.trip_id == null) {
        acc.deadRun += 1;
      }

      return acc;
    },
    {
      [DelayState.EARLY]: 0,
      [DelayState.LATE]: 0,
      [DelayState.ON_TIME]: 0,
      deadRun: 0,
    },
  );
});

watch(
  devicesStatus,
  () => {
    updateLivePunctuality();
  },
  { immediate: true },
);

watch(
  onlineDevices,
  () => {
    firstLivePunctualityLoading.value = false;
  },
  { immediate: true },
);

function updateLivePunctuality() {
  livePunctuality.value = [
    {
      color: '#00b871', // $primary-light
      name: /** @type {string} */ (t(`delayState.${DelayState.ON_TIME}`)),
      value: devicesStatus.value[DelayState.ON_TIME],
    },
    {
      color: '#EB5757', // $danger
      name: /** @type {string} */ (t(`delayState.${DelayState.EARLY}`)),
      value: devicesStatus.value[DelayState.EARLY],
    },
    {
      color: '#f99c49', // $warn
      name: /** @type {string} */ (t(`delayState.${DelayState.LATE}`)),
      value: devicesStatus.value[DelayState.LATE],
    },
  ];
  firstLivePunctualityLoading.value = false;
}
// #endregion

// #region Trip Tracking
import { TripTrackingReportHelper } from '@/pages/ReportsPage/ReportsTripTracking/ReportsTripTrackingHelper.js';

/** @type {import('vue').Ref<Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>>} */
const tripTrackingChartSeries = ref([]);
/** @type {import('vue').Ref<Array<import('@/api').TripTracking>>} */
const tripTrackingChartData = ref([]);
/** @type {import('vue').Ref<Object>} */
const tripTrackingChartTooltip = ref({});

/** @type {import('vue').Ref<Boolean | null>} */
const tripTrackingLoading = ref(true);

async function updateStatsTripTracking() {
  tripTrackingLoading.value = true;
  const responsePayload = await ApiStats.getTripTracking(group.value._id, GroupBy.DAY, from.value, to.value);
  const formattedData = responsePayload.map(item =>
    TripTrackingReportHelper.getGroupedInfos(item, GroupBy.DAY),
  );
  // Add null for days with no data
  tripTrackingChartData.value = historyDates.value.map(day => {
    return formattedData.find(el => el.date === timestampToGtfsFormat(day, true)) || null;
  });

  tripTrackingChartSeries.value = TripTrackingReportHelper.getSeries(tripTrackingChartData.value);
  tripTrackingChartTooltip.value = {
    custom: ({ dataPointIndex }) => {
      const entry = tripTrackingChartData.value[dataPointIndex];
      return TripTrackingReportHelper.createTooltip(entry);
    },
  };

  tripTrackingLoading.value = false;
}
// #endregion

// #region Punctuality history
import {
  PunctualityReportsHelper,
  SeriesCorrespondance,
} from '@/pages/ReportsPage/ReportsPunctuality/PunctualityReportsHelper.js';

/** @type {import('vue').Ref<Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>>} */
const punctualityChartSeries = ref([]);
/** @type {import('vue').Ref<Array<import('@/api').PunctualityByDay>>} */
const punctualityChartData = ref([]);
/** @type {import('vue').Ref<Object>} */
const punctualityChartTooltip = ref({});
/** @type {import('vue').Ref<Boolean | null>} */
const dailyPunctualityLoading = ref(true);

async function updateStatsDailyPunctuality() {
  dailyPunctualityLoading.value = true;
  const today = dateGtfsFormatToObj(dateObjToGtfsFormat(dateOfTheDay.value));
  const sevenDaysAgoTs = new Date(today).setDate(today.getDate() - 7) / 1000;
  const tomorrowTs = new Date(today).setDate(today.getDate() + 1) / 1000;
  const response = await ApiStats.getPunctualityStats(
    group.value._id,
    sevenDaysAgoTs,
    tomorrowTs,
    GroupBy.DAY,
  );
  // Add null for days with no data
  punctualityChartData.value = historyDates.value.map(day => {
    return response.find(el => el.start_date === timestampToGtfsFormat(day, true)) || null;
  });
  punctualityChartSeries.value = PunctualityReportsHelper.getSeries(punctualityChartData.value);
  punctualityChartTooltip.value = {
    custom: ({ seriesIndex, dataPointIndex }) => {
      const focusedData = punctualityChartData.value[dataPointIndex];
      const key = SeriesCorrespondance[seriesIndex];
      return PunctualityReportsHelper.createTooltip(focusedData, key);
    },
  };

  dailyPunctualityLoading.value = false;
}
// #endregion

// #region Trip Km
import { TripKmReportsHelper } from '@/pages/ReportsPage/ReportsTripKm/TripKmReportsHelper.js';

/** @type {import('vue').Ref<Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>>} */
const tripKmChartSeries = ref([]);
/** @type {import('vue').Ref<Array<import('@/api').PunctualityByDay>>} */
const tripKmChartData = ref([]);
/** @type {import('vue').Ref<Object>} */
const tripKmChartTooltip = ref({});
/** @type {import('vue').Ref<Boolean | null>} */
const dailyKmLoading = ref(true);

async function updateStatsTripsKm() {
  dailyKmLoading.value = true;

  const response = await ApiStats.getTripKM(group.value._id, GroupBy.DAY, from.value, to.value);
  const formattedData = response
    .map(item => TripKmReportsHelper.getGroupByColumnsInfos(item, GroupBy.DAY))
    .sort((a, b) => a.date - b.date);
  // Add null for days with no data
  tripKmChartData.value = historyDates.value.map(day => {
    return formattedData.find(el => el.date === timestampToGtfsFormat(day, true)) || null;
  });

  tripKmChartSeries.value = TripKmReportsHelper.getSeries(tripKmChartData.value);
  tripKmChartTooltip.value = {
    custom: ({ dataPointIndex }) => {
      const entry = tripKmChartData.value[dataPointIndex];
      if (entry) {
        const { theoricalKm, recordedKm, percent, unreliableKm } = entry;
        return TripKmReportsHelper.createTooltip(theoricalKm, recordedKm, percent, unreliableKm);
      } else {
        return '';
      }
    },
  };

  dailyKmLoading.value = false;
}
// #endregion

// #region Passenger count
import { PassengerCountReportsHelper } from '@/pages/ReportsPage/ReportsPassengerCount/PassengerCountReportsHelper.js';

/** @type {import('vue').Ref<Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>>} */
const passengerCountChartSeries = ref([]);
/** @type {import('vue').Ref<Array<import('@/api').PunctualityByDay>>} */
const passengerCountChartData = ref([]);
/** @type {import('vue').Ref<Object>} */
const passengerCountChartTooltip = ref({});
/** @type {import('vue').Ref<Boolean | null>} */
const passengerCountLoading = ref(true);

async function updateStatsDailyPassengerCounts() {
  passengerCountLoading.value = true;
  const response = await ApiStats.getPassengerCounts(group.value._id, GroupBy.DAY, from.value, to.value);
  const formattedData = response.map(item => PassengerCountReportsHelper.getGroupedInfos(item, GroupBy.DAY));
  // Add null for days with no data
  passengerCountChartData.value = historyDates.value.map(day => {
    return formattedData.find(el => el.date === timestampToGtfsFormat(day, true)) || null;
  });

  passengerCountChartSeries.value = PassengerCountReportsHelper.getSeries(passengerCountChartData.value);
  passengerCountChartTooltip.value = {
    custom: ({ seriesIndex, dataPointIndex }) => {
      const focusedData = passengerCountChartData.value[dataPointIndex];
      const customCategories = PassengerCountReportsHelper.getCustomCategories(passengerCountChartData.value);
      return PassengerCountReportsHelper.createTooltip(focusedData, customCategories, seriesIndex);
    },
  };

  passengerCountLoading.value = false;
}
// #endregion
</script>

<template>
  <div class="dashboard">
    <DashboardHeader :is-gtfs-loading="isGtfsLoading" :has-gtfs="hasGtfs" :gtfs-name="gtfsName" />
    <v-container fill-height fluid class="dashboard__main flex-wrap">
      <v-row class="dashboard__main-row">
        <v-col class="dashboard__column d-flex flex-column" :cols="cols[0]">
          <v-row dense no-gutters>
            <v-col cols="6">
              <MetricCard
                class="metric-card-1"
                :title="t('dashboard.duties')"
                :data="dutyListLength"
                @click="redirect(GroupRoute.DUTY_LIST)"
              />
            </v-col>
            <v-col cols="6">
              <MetricCard
                class="metric-card-2"
                :title="t('dashboard.scheduledTrips')"
                :data="tripListLength"
                @click="redirect(GroupRoute.TRIP_LIST)"
              />
            </v-col>
          </v-row>
          <div>
            <FeaturesCard :no-gtfs="!hasGtfs" />
          </div>
        </v-col>
        <v-col class="dashboard__column" :cols="cols[1]">
          <MapCard
            :loading="isGtfsLoading"
            :has-gtfs="hasGtfs"
            :title="t('dashboard.map')"
            @redirect="redirect(GroupRoute.LIVE_MAP)"
          />
        </v-col>
        <v-col class="dashboard__column" :cols="cols[2]">
          <DonutChartCard
            :title="t('charts.delay')"
            :data="livePunctuality"
            :has-gtfs="hasGtfs"
            :loading="isGtfsLoading || firstLivePunctualityLoading"
            @redirect="redirect(GroupRoute.TRIP_LIST)"
          />
        </v-col>
        <v-col class="dashboard__column" :cols="cols[3]">
          <BarChartCard
            :categories="categories"
            :tooltip="tripTrackingChartTooltip"
            :series="tripTrackingChartSeries"
            :title="t('charts.tripTracking')"
            :subtitle="t('dashboard.lastSevenDays')"
            :loading="tripTrackingLoading"
            :has-gtfs="hasGtfs"
            placeholder-name="trip-tracking"
            percentage
            @redirect="redirect(GroupRoute.REPORTING_TRIP_TRACKING)"
          />
        </v-col>
        <v-col class="dashboard__column" :cols="cols[4]">
          <BarChartCard
            :categories="categories"
            :series="punctualityChartSeries"
            :tooltip="punctualityChartTooltip"
            :title="t('charts.punctuality')"
            :subtitle="t('dashboard.lastSevenDays')"
            :loading="dailyPunctualityLoading"
            :has-gtfs="hasGtfs"
            :show-data-labels="false"
            percentage
            placeholder-name="punctuality"
            @redirect="redirect(GroupRoute.REPORTING_PUNCTUALITY, { startDate: from, endDate: to })"
          />
        </v-col>
        <v-col class="dashboard__column" :cols="cols[5]">
          <BarChartCard
            :categories="categories"
            :series="tripKmChartSeries"
            :tooltip="tripKmChartTooltip"
            :title="t('charts.tripKm')"
            :subtitle="t('dashboard.lastSevenDays')"
            :loading="dailyKmLoading"
            :has-gtfs="hasGtfs"
            :show-data-labels="false"
            placeholder-name="trip-vk"
            @redirect="redirect(GroupRoute.REPORTING_TRIP_KM)"
          />
        </v-col>
        <v-col class="dashboard__column" :cols="cols[6]">
          <BarChartCard
            :categories="categories"
            :series="passengerCountChartSeries"
            :tooltip="passengerCountChartTooltip"
            :title="t('charts.passengerCount')"
            :subtitle="t('dashboard.lastSevenDays')"
            :loading="passengerCountLoading"
            :has-gtfs="hasGtfs"
            placeholder-name="passenger-count"
            @redirect="redirect(GroupRoute.REPORTING_PASSENGER_COUNTS)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style lang="scss">
.dashboard {
  .v-card {
    border-radius: 12px;
  }

  .metric-card-1 {
    border-right: 1px solid $light-border;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .metric-card-2 {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__column {
    min-width: 300px;
    height: 300px;

    @media (min-width: 1400px) {
      min-width: 400px;
      height: 400px;
    }
  }

  &__main {
    padding: $view-standard-padding;
    background-color: $background;
  }

  &__main-row {
    // 12px = cards margin
    gap: calc($view-standard-padding - (12px * 2));
  }
}
</style>
