import { baseAppUrl } from '@/api';

export const API_URL_QR = '/api/v2/qrcode';

export const getScreenQRDataParams = (groupId: string, stopId: string, stopName: string): URLSearchParams => {
  const item = getScreenQRData(groupId, stopId, stopName);
  return new URLSearchParams([
    ['content', item.content],
    ['filename', item.filename],
    ['label', item.label],
  ]);
};

/**
 * Get the QrData for a screen stop
 */
export const getScreenQRData = (groupId: string, stopId: string, stopName: string): QRData => {
  return {
    content: encodeURI(`${baseAppUrl}/screen/#/${groupId}/stop-detailed/${stopId}`),
    filename: `${stopName}(${stopId})`,
    label: `${stopName} (id : ${stopId})`,
  };
};

export interface QRData {
  content: string;
  filename: string;
  label: string;
}
