import cloneDeep from 'clone-deep';
// eslint-disable-next-line no-unused-vars
import { TripStatusType, UpdateType, TemporalityType } from '@/api';
import { NO_DATA } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { dateToFormattedString, DatetimeFormat } from '@/libs/helpers/dates';
import { localeCompareSort } from '@/libs/helpers/strings';
import { STATUSES } from './Trips.conf';
import i18n from '@/i18n';
// eslint-disable-next-line no-unused-vars

const { t } = i18n.global;

export const IN_PROGRESS_TAB_STATUS = 'inProgress';

export const TripListDataFormatter = {
  /**
   * Add data in the format expected by the datagrid.
   * @param {import('@/api').TripListItemV4} d
   * @param {string} tz
   * @returns {TripListItemFormattedV4}
   */
  formatData(d, tz = 'UTC') {
    /** @type {TripListItemFormattedV4} */
    // @ts-ignore
    const newObj = cloneDeep(d);

    newObj.tripDate = this.getTripDate(d);
    newObj.tripStatusValue = this.getTripStatus(d);
    newObj.routeValue = d.route.short_name || d.route.long_name || d.route.id;
    newObj.percentKmValue = d.percent_km ? Math.round(Number(d.percent_km) / 10) * 10 : null;
    newObj.gtfsValue = d.gtfs || [];
    newObj.tripTeamValue = d.team?.id;
    newObj.tripTeamColor = d.team?.color;
    newObj.devicesName = d.devices ? d.devices.map(d => d.name || d.id) : [];
    newObj.deviceTeamValue = d.devices ? d.devices[0]?.team?.id : null;
    newObj.deviceTeamColor = d.devices ? d.devices[0]?.team?.color : null;
    newObj.firstStopValue = this.formatStop(d.first_stop);
    newObj.lastStopValue = this.formatStop(d.last_stop);
    newObj.vkOrReliableKm = this.formatKM(d.reliable_km || d.vk);
    newObj.tkValue = this.formatKM(d.tk);
    newObj.unreliableKmValue = this.formatKM(d.unreliable_km);
    newObj.serviceValue = d.service ? (d.service.recorded_stops / d.service.planned_stops) * 100 : null;
    newObj.formattedDrivers = this.formatDriverOrVehicle(d.drivers, 'name');
    newObj.formattedVehicles = this.formatDriverOrVehicle(d.vehicles, 'license_plate');
    newObj.passengerCountBoardingValue = this.formatPassengerCount(d, 'boardings');
    newObj.passengerCountAlightingsValue = this.formatPassengerCount(d, 'alightings');
    newObj.passengerCountLoadingValue = this.formatPassengerCount(d, 'loading');
    newObj.departureTimeValue = this.formatTimeHHMM(d.departure_time, tz);
    newObj.recordedDepartureTimeValue = this.formatTimeHHMM(d.departure_stored, tz);
    newObj.arrivalTimeValue = this.formatTimeHHMM(d.arrival_time, tz);
    newObj.recordedArrivalTimeValue = this.formatTimeHHMM(d.arrival_stored, tz);
    newObj.stopInfoValue = this.findUpdateData(d.updates, UpdateType.STOP_INFO);
    newObj.commentValue = this.findUpdateData(d.updates, UpdateType.COMMENT);
    newObj.tabTripStatus = this.findTabTripStatus(d);
    return newObj;
  },

  /**
   * Return the name || code || id from a stop
   * @param {import('@/api').TripListStop} stop
   * @returns {string}
   */
  formatStop(stop) {
    if (!stop) return null;
    return stop.name || stop.code || stop.id;
  },

  /**
   * Format a km value to 3 maximum digits, return '-' if no data
   * @param {number} value
   * @returns {string}
   */
  formatKM(value) {
    if (Number.isNaN(Number(value)) || value === 0) return NO_DATA;
    return value.toFixed(3);
  },

  /**
   * Format a timestamp to HH:MM
   * @param {number} value
   * @returns {string}
   */
  formatTimeHHMM(value, tz) {
    if (!value) return NO_DATA;
    return dateToFormattedString(value, { format: DatetimeFormat.HHMM, unix: true, tz });
  },

  /**
   * Get an array of trip status with or without 'In progress' status - used exclusively for the tabs
   * @param {TripListItemFormatted} data
   * @returns {Array<TripStatusType>}
   */
  findTabTripStatus(data) {
    const statuses = this.getTripStatus(data);
    if (data.temporality === TemporalityType.UNDERWAY) {
      statuses.push(t(IN_PROGRESS_TAB_STATUS));
    }
    return statuses;
  },

  /**
   * find update(s) based on its UpdateType
   * @param {import('@/api').TripUpdates} updates
   * @param {UpdateType} type
   * @returns {any}
   */
  findUpdateData(updates, type) {
    switch (type) {
      case UpdateType.DELAY:
        return updates.delay;
      case UpdateType.COMMENT:
        return updates.comment;
      case UpdateType.STOP_INFO:
        return updates.stop_infos;
      case UpdateType.DO_NOT_SERVE:
        return updates.skipped_stop_sequences;
      case UpdateType.TRIP_CANCELED:
        return updates.canceled;
      default:
        return null;
    }
  },

  /**
   * Check if key is not null or 0 & return a passenger count based of key
   * @param {TripListItemFormattedV4} data
   * @param {string} key
   * @returns {number}
   */
  formatPassengerCount(data, key) {
    const passengerCount = data.passenger_count;
    return passengerCount && passengerCount[key] !== 0 ? passengerCount[key] : NO_DATA;
  },

  /**
   * Format a list of driver or vehicle to an array of name
   * @param {Array<import('@/store/drivers').Driver> | Array<import('@/store-pinia/vehicles').Vehicle>} datas
   * @param {string} key
   * @returns {Array<{ text: string }>}
   */
  formatDriverOrVehicle(datas, key) {
    if (!datas || datas.length === 0) return null;
    /** @type {Array<import('@/components/common/DropList.vue').DropListValue>} */
    const formattedObject = datas?.map(d => {
      return {
        text: d && key in d ? d[key] : NO_DATA,
      };
    });

    if (formattedObject?.length > 1) {
      formattedObject.sort((a, b) => localeCompareSort(a.text, b.text, i18n.global.locale));
    }
    return formattedObject;
  },

  /**
   * Get trip date to format YYYY-MM-DD
   * @param {import('@/api').TripListItemV4} data
   * @returns {string}
   */
  getTripDate(data) {
    let date;
    if (data.service_date) {
      const year = data.service_date.substring(0, 4);
      const month = data.service_date.substring(4, 6);
      const day = data.service_date.substring(6, 8);
      date = `${year}-${month}-${day}`;
    } else {
      date = dateToFormattedString(data.departure_time, {
        format: DatetimeFormat.DDMMYYYY,
        unix: true,
      });
    }
    return date;
  },

  /**
   * Get an array of trip status from trip_status or trips if merged trip
   * @param {import('@/api').TripListItemV4} data
   * @returns {Array<TripStatusType>}
   */
  getTripStatus(data) {
    // trip-status value multiple if has child trips
    return data.trips?.length > 0
      ? data.trips.map(trip => t(`tripStatus.${STATUSES[trip.status].localeKey}`))
      : [t(`tripStatus.${STATUSES[data.status].localeKey}`)];
  },
};

/**
 * @typedef {Object} ExtendedTripListDatas
 * @property {string} tripDate
 * @property {Array<TripStatusType>} tripStatusValue
 * @property {string} routeValue
 * @property {number} percentKmValue
 * @property {Array<import('@/api').TripListGtfs>} gtfsValue
 * @property {Array<string>} devicesName
 * @property {string} deviceTeamValue
 * @property {string} deviceTeamColor
 * @property {string} deviceTeamName
 * @property {string} tripTeamName
 * @property {string} firstStopValue
 * @property {string} lastStopValue
 * @property {string} vkOrReliableKm
 * @property {string} tkValue
 * @property {string} unreliableKmValue
 * @property {number} serviceValue
 * @property {string} tripTeamValue
 * @property {string} tripTeamColor
 * @property {Array<{ text: string }>} formattedDrivers
 * @property {Array<{ text: string }>} formattedVehicles
 * @property {number} passengerCountBoardingValue
 * @property {number} passengerCountAlightingsValue
 * @property {number} passengerCountLoadingValue
 * @property {string} departureTimeValue
 * @property {string} recordedDepartureTimeValue
 * @property {string} arrivalTimeValue
 * @property {string} recordedArrivalTimeValue
 * @property {Array<import('@/api').StopInfo>} stopInfoValue
 * @property {string} commentValue
 * @property {Array<ExtendedTripListDatas>} childrenItems
 * @property {import('@/components/Table/DataGridVuetify/models/DataGrid.models').StateMergingChildren} displayChildren
 * @property {Array<TripStatusType>} tabTripStatus
 * @property {string} [defaultId]
 */

/**
 * @typedef {import('@/api').TripListItemV4 & ExtendedTripListDatas} TripListItemFormattedV4
 */
