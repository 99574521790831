<template>
  <div class="drivers">
    <div class="drivers__header">
      <div class="drivers__header-side">
        <Btn
          v-if="$store.getters.hasPermission(Permission.EXPORT_DRIVERS)"
          type="secondary"
          class="drivers__btn"
          @click="downloadData"
        >
          <font-awesome-icon icon="fa-download" />
          {{ $t('download') }}
        </Btn>

        <v-menu offset="8">
          <template #activator="{ props }">
            <Btn type="primary" class="dropdown-btn" :disabled="lineInEdition" v-bind="props">
              <i class="fas fa-plus" aria-hidden="true"></i>
              {{ $t('addDriver') }}
            </Btn>
          </template>
          <v-list class="dropdown-content topbar-dropdown__list">
            <v-list-item
              v-for="(item, index) in dropdownItems"
              :key="index"
              class="dropdown-item"
              @click="handleDropdownSelect(item.action)"
            >
              <template #prepend>
                <v-icon size="small" :class="item.iconClass" />
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('drivers', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="driversFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_DRIVER)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalComment
      v-if="modalShown === ModalType.COMMENT"
      :comment="modalDriverInitialState.comment ? modalDriverInitialState.comment : null"
      :title-name="modalDriverInitialState.driver_name"
      @close="closeModal"
      @submit="setDriverComment"
    />

    <ModalImportResource
      v-if="modalShown === ModalType.IMPORT_DRIVER"
      resource-type="drivers"
      @close="closeModalImport"
    />

    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_DRIVER"
      :title="$t('archiveDriver')"
      :body="$t('confirmArchiveDriver', [modalDriverInitialState.driver_name])"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import ModalComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { ColumnKey, getDatagrid } from '@/pages/DriverListPage/DriverList.conf.js';
import { Permission } from '@/auth';
import ModalImportResource from '@/components/common/ModalImportResource.vue';

const toast = useToast();

/** @enum {string} */
export const ModalType = {
  COMMENT: 'comment',
  ARCHIVE_DRIVER: 'archive_driver',
  IMPORT_DRIVER: 'import_driver',
};

export const NEW_LINE_ID = 'new-line';

export const validateDriverCode = {
  IS_FOUR_DIGIT_OR_EMPTY: value => {
    if (!value) return true;
    return /^\d{4}$/.test(value);
  },
};

/** @enum {string} */
export const DropdownActions = {
  NEW_DRIVER: 'newDriver',
  IMPORT_DRIVER: 'importDriver',
};

export default {
  name: 'DriverList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalComment,
    ModalImportResource,
    ModalArchiveRestore,
  },

  data() {
    return {
      NEW_LINE_ID,
      ColumnKey,
      ModalType,
      Permission,

      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {?String} */
      inEditionId: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {?import('@/store/drivers').Driver} */
      modalDriverInitialState: null,
      /** @type {ModalType} */
      modalShown: '',
      /** @type {Array<import('@/store/drivers').Driver>} */
      driversFormatted: [],
      /** @type {?import('@/store/drivers').Driver} */
      driverInEditionOriginalState: null,
      /** @type {Array<string>} */
      driverCodeList: [],
      /** @type {number} */
      renderedDataLength: null,
      dropdownItems: [
        { label: this.$t('manually'), action: DropdownActions.NEW_DRIVER, iconClass: 'fas fa-pen' },
        {
          label: this.$t('import'),
          action: DropdownActions.IMPORT_DRIVER,
          iconClass: 'fa fa-arrow-circle-up',
        },
      ],
    };
  },

  computed: {
    /** @return {{[key in ColumnKey]: (data: {apiData: import('@/store/drivers').Driver}) => Object}} */
    buildCellInjectors() {
      /**
       * @param {import('@/store/drivers').Driver} apiDataRow
       * @return {({ name: string }) => void}
       */
      const bindActionModal = (apiDataRow, name) => () => {
        this.showModal(apiDataRow, name);
      };

      /**
       * @param {import('@/store/drivers').Driver} apiDataRow
       * @return {({ value: object, field: string }) => void}
       */
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, value, field);
        };

      return {
        [ColumnKey.COMMENT]: ({ apiData }) => ({ showModal: bindActionModal(apiData, ModalType.COMMENT) }),
        [ColumnKey.DRIVER_NAME]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.CODE]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.STAFF_NUMBER]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.TEAM]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {Array<import('@/store/drivers').Driver>} */
    driverList() {
      return Object.values(this.$store.state.drivers.list);
    },

    /** @return {Boolean} */
    lineInEdition() {
      return this.inEditionId != null;
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    addNewDriver() {
      if (!this.driversFormatted.find(v => v.id === NEW_LINE_ID)) {
        this.inEditionId = NEW_LINE_ID;
        this.driversFormatted.unshift({
          id: NEW_LINE_ID,
          comment: '',
          driver_name: '',
          staff_number: '',
          team_id: null,
        });
      }
    },

    /** Close the opened modal */
    closeModal() {
      this.modalShown = '';
      this.modalDriverInitialState = null;
    },

    /** Close the import modal and refresh data */
    closeModalImport() {
      this.closeModal();
      this.getDataList();
    },

    downloadData() {
      window.open(`/api/v3/groups/${this.group._id}/export/drivers`);
    },

    async onCreate() {
      this.loading = true;
      await this.$store.dispatch('drivers/loadList');
      this.getDataList();

      this.loading = false;
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.driversFormatted.findIndex(el => el.id === id);
      if (id === NEW_LINE_ID) {
        this.driversFormatted.splice(elementIndex, 1);
      } else {
        // give the element its original values back
        this.driversFormatted[elementIndex] = { ...this.driverInEditionOriginalState };
      }
    },

    /**
     * Edit driver
     * @param {import('@/store/drivers').Driver} apiDataRow
     */
    async saveEdits(apiDataRow) {
      if (apiDataRow.id === NEW_LINE_ID) {
        delete apiDataRow.id;
        if (apiDataRow.driver_name === '') {
          apiDataRow.driver_name = this.$t('newDriver');
        }
        await this.$store.dispatch('drivers/createDriver', apiDataRow);
        await this.$store.dispatch('drivers/loadList');
        this.getDataList();
      } else {
        // Update existing driver :
        const previousDriverCode = this.driverInEditionOriginalState?.code;
        const newDriverCode = apiDataRow.code || null;
        if (newDriverCode !== previousDriverCode && !this.validateDriverCode(newDriverCode)) return;
        const driver = { ...apiDataRow, code: newDriverCode };
        delete driver.archived;
        delete driver.teamName;
        await this.$store.dispatch('drivers/updateDriver', {
          driverId: apiDataRow.id,
          driver,
        });
        this.getDataList();
      }
      this.inEditionId = null;
    },

    /** @param {import('@/store/drivers').Driver['comment']} comment */
    async setDriverComment(comment) {
      this.modalDriverInitialState.comment = comment;
      const apiDataRow = this.driversFormatted.find(
        element => element.id === this.modalDriverInitialState.id,
      );
      this.updateValue(apiDataRow, comment, 'comment');
      // the comment is not saved immediately in case of a new driver or edition mode
      if (
        this.modalDriverInitialState.id !== NEW_LINE_ID &&
        this.modalDriverInitialState.id !== this.inEditionId
      ) {
        const driver = { ...this.modalDriverInitialState };
        delete driver.id;
        delete driver.archived;
        await this.$store.dispatch('drivers/updateDriver', {
          driverId: this.modalDriverInitialState.id,
          driver,
        });
      }
      this.closeModal();
    },

    /**
     * Show a modal
     * @param {import('@/store/drivers').Driver} apiDataRow
     * @param {ModalType} modalName
     */
    showModal(apiDataRow, modalName) {
      this.modalDriverInitialState = { ...apiDataRow };

      this.modalShown = modalName;
    },

    /**
     * Delete driver
     */
    async submitModalRemove() {
      await this.$store.dispatch('drivers/deleteDriver', this.modalDriverInitialState.id);
      this.getDataList();
      this.closeModal();
    },

    /**
     * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
     * @param {import('@/store/drivers').Driver} apiDataRow
     * @param {boolean} editionMode
     */
    toggleEditionMode(apiDataRow, editionMode) {
      if (editionMode) {
        // if another edition was in progress, cancel it
        if (this.inEditionId) {
          this.resetLine(this.inEditionId);
        }
        this.inEditionId = apiDataRow.id;
        // keep a copy of the element about to be edited in case of edit cancellation
        this.driverInEditionOriginalState = { ...apiDataRow };
      } else {
        this.resetLine(apiDataRow.id);
        this.inEditionId = null;
        this.driverInEditionOriginalState = null;
      }
    },

    /**
     * Update a value - triggered upon focus lost on an EditableCell
     * @param {import('@/store/drivers').Driver} apiDataRow
     * @param {string} value
     * @param {keyof import('@/store/drivers').Driver} field
     */
    updateValue(apiDataRow, value, field) {
      apiDataRow[field] = value;
    },

    getDataList() {
      const data = this.driverList
        .map(driver => {
          const newObject = driver;
          newObject.team_id =
            driver.team_id && !['', 'None'].includes(driver.team_id) ? driver.team_id : null;
          newObject.comment = driver.comment !== 'None' ? driver.comment : null;
          newObject.teamName = this.$store.getters.getTeamNameById(newObject.team_id);
          return newObject;
        })
        .sort((a, b) => {
          if (a.driver_name.toLowerCase() > b.driver_name.toLowerCase()) return 1;
          if (a.driver_name.toLowerCase() < b.driver_name.toLowerCase()) return -1;
          return 0;
        });
      const driversCodes = data.map(driver => driver.code).filter(code => code !== null);
      this.driverCodeList = driversCodes;
      this.driversFormatted = data;
    },

    /**
     * @param {import('@/store/drivers').Driver['code']} driverCode
     * @return {boolean}
     */
    validateDriverCode(driverCode) {
      if (!validateDriverCode.IS_FOUR_DIGIT_OR_EMPTY(driverCode)) {
        const toastId = toast.error(this.$t('validation.fourDigitCode'), { position: 'bottom-right' });
        setTimeout(() => toast.dismiss(toastId), 5000);
        return false;
      }
      return true;
    },
    handleDropdownSelect(action) {
      if (action === DropdownActions.NEW_DRIVER) {
        this.addNewDriver();
      } else if (action === DropdownActions.IMPORT_DRIVER) {
        this.showModal(null, ModalType.IMPORT_DRIVER);
      }
    },
  },
};
</script>

<style lang="scss">
.drivers {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  .dropdown-btn i {
    margin-right: 8px;
  }

  .fa-download {
    margin-right: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "archiveDriver": "Archiver le conducteur",
  "confirmArchiveDriver": "Êtes-vous sûr de vouloir archiver le conducteur \"{0}\" ?",
  "addDriver": "Ajouter un conducteur",
  "import": "Importer",
  "manually": "Manuellement",
  "drivers": "conducteur | conducteurs",
  "validation": {
    "fourDigitCode": "Le code conducteur doit être composé de 4 chiffres"
  }
}
</i18n>

<i18n locale="en">
{
  "archiveDriver": "Archive the driver",
  "confirmArchiveDriver": "Do you want to archive the driver \"{0}\"?",
  "addDriver": "Add Driver",
  "import": "Import",
  "manually": "Manually",
  "drivers": "driver | drivers",
  "validation": {
    "fourDigitCode": "Driver code must be 4 digits long"
  }
}
</i18n>
