<template>
  <div class="period-select">
    <div class="period-select__group">
      <span class="period-select__label">{{ $t('from') }}</span>
      <HeaderDatepicker
        v-model:value="startDateValue"
        :disabled="getDisabledDates('start')"
        without-arrows
        class="datepicker-input"
      />
      <span class="period-select__label">{{ $t('to') }}</span>
      <HeaderDatepicker
        v-model:value="endDateValue"
        :disabled="getDisabledDates('end')"
        without-arrows
        class="datepicker-input"
      />
      <Btn
        type="secondary"
        class="period-select__apply"
        @click="$emit('dates-selected', { from: startDateValue, to: endDateValue })"
      >
        <font-awesome-icon icon="chart-simple" />
        <span>{{ $t('apply') }}</span>
      </Btn>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import HeaderDatepicker from './HeaderDatepicker.vue';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'PeriodSelect',

  components: {
    HeaderDatepicker,
    Btn,
  },

  props: {
    /** @type {Vue.PropOptions<Date>} */
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    /** @type {Vue.PropOptions<Date>} */
    endDate: {
      type: Date,
      default: () => new Date(),
    },
    limitedPeriod: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['dates-selected'],

  data() {
    return {
      /** @type {Date} */
      endDateValue: this.endDate,

      /** @type {Date} */
      startDateValue: this.startDate,
    };
  },

  computed: {
    /** @return {Boolean} */
    isNewDateSelected() {
      return (
        dateObjToGtfsFormat(this.startDateValue) !== dateObjToGtfsFormat(this.startDate) ||
        dateObjToGtfsFormat(this.endDateValue) !== dateObjToGtfsFormat(this.endDate)
      );
    },
    endDateMax() {
      const currentDate = new Date();
      // If punctuality mode, block to 1 month max the interval periode
      if (this.limitedPeriod) {
        const copyDate = dayjs(this.startDateValue);
        const startDatePlusOneMonth = copyDate.add(30, 'day').toDate();

        return currentDate < startDatePlusOneMonth ? currentDate : startDatePlusOneMonth;
      }
      // else block to current date
      return currentDate;
    },
  },

  watch: {
    startDate() {
      this.startDateValue = this.startDate;
    },
    endDate() {
      this.endDateValue = this.endDate;
    },
    startDateValue() {
      if (this.limitedPeriod && this.endDateValue > this.endDateMax) {
        this.endDateValue = this.endDateMax;
      } else if (this.startDateValue > this.endDateValue) {
        this.endDateValue = this.startDateValue;
      }
    },
  },

  methods: {
    /**
     * @param {'start'|'end'} calendarType
     * @return {import('./Datepicker.vue').DisabledDates}
     */
    getDisabledDates(calendarType) {
      const dateFirstPublish = this.$store.getters.getDateFirstPublish;
      const dfp = dateFirstPublish.split('T')[0].replaceAll('-', '');

      if (calendarType === 'start') return { minDate: dateGtfsFormatToObj(dfp), maxDate: new Date() };
      if (calendarType === 'end') return { minDate: this.startDateValue, maxDate: this.endDateMax };

      return {};
    },
  },
};
</script>

<style lang="scss">
.period-select {
  display: flex;
  align-items: center;
  margin-left: 10px;

  &__group {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__apply {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 10px 15px;
    border: 1px solid $border;
    border-radius: 5px;
    background: $canvas;
    color: $primary-light;
    box-shadow: 0 4px 7px rgb(0 0 0 / 10%);
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    font-family: $font-poppins;
    cursor: pointer;
    transition-duration: 0.2s;
    transition-property: all;

    &:hover {
      background: $background;
    }

    font-awesome-icon {
      margin-right: 5px;
    }
  }

  &__input {
    width: 120px;
    height: 40px;
    padding-left: 1em;
    border: 2px solid $primary-light;
    border-radius: 9px;
    background: $canvas;
    color: $primary-light;
    box-shadow: 0 4px 7px rgb(0 0 0 / 10%);
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
    font-family: $font-poppins;
    cursor: pointer;

    &:focus-visible {
      outline: 0;
    }
  }

  .datepicker-input {
    height: 39px;
  }

  &__label {
    height: 20px;
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }
}
</style>

<i18n locale="fr">
{
  "from": "DU",
  "to": "AU",
  "apply": "Appliquer"
}
</i18n>

<i18n locale="en">
{
  "from": "FROM",
  "to": "TO",
  "apply": "Apply"
}
</i18n>
