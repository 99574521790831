<script setup>
const propsItems = defineProps({
  stop: {
    type: Object,
    required: true,
  },
  isBeforeFirstStop: {
    type: Boolean,
    default: false,
  },
});

function searchStop() {
  // TODO TO IMPLEMENT IN ANOTHER TICKET
  console.log('search stop', propsItems.stop, props.isBeforeFirstStop);
}
function addManualStop(st) {
  // TODO TO IMPLEMENT IN ANOTHER TICKET
  console.log('add manual stop', propsItems.stop, props.isBeforeFirstStop);
}
</script>

<template>
  <v-menu offset="8" transition="scale-transition" class="add-temporary-stop">
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        size="small"
        disabled
        elevation="0"
        :ripple="false"
        density="compact"
        class="add-temporary-stop__btn"
        :class="{ 'add-temporary-stop__btn-before-first-stop': isBeforeFirstStop }"
        icon="fas fa-plus"
      ></v-btn>
    </template>
    <v-list class="topbar-dropdown__list">
      <v-list-item @click="addManualStop()">
        <template #prepend>
          <font-awesome-icon class="mr-3" icon="fa-pen" />
        </template>
        <v-list-item-title>{{ $t('addStopManually') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="searchStop()">
        <template #prepend>
          <font-awesome-icon class="mr-3" icon="fa-search" />
        </template>
        <v-list-item-title>{{ $t('searchStop') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss">
.add-temporary-stop {
  &__btn {
    position: absolute;
    left: 5px;
    z-index: 2;
    margin-top: 30px;
    border: 1px solid $border;
    border-radius: 5px;
    background-color: $canvas;
    box-shadow: initial;
    transform: rotate(-45deg);

    // TO REMOVE WHEN FEATURE IS AVAILABLE
    &.v-btn--disabled {
      opacity: 0.7 !important;
    }

    .v-btn__overlay {
      background-color: inherit;
    }

    i {
      color: $text-dark-variant;
      font-size: 14px;
      transform: rotate(-45deg);
    }
  }

  &__btn:hover,
  &__btn[aria-expanded='true'] {
    border-color: $text-dark;

    i {
      border-color: $text-dark;
      color: $text-dark;
    }
  }

  &__btn-before-first-stop {
    margin-top: -30px;
  }

  &__btn:not([aria-expanded='true']) {
    display: none;
  }
}
</style>

<i18n locale="fr">
    {
      "addStopManually": "Ajouter un arrêt manuellement",
      "searchStop": "Rechercher un arrêt existant"
    }
    </i18n>

<i18n locale="en">
    {
      "addStopManually": "Add a stop manually",
      "searchStop": "Search for an existing stop"
    }
</i18n>
