<template>
  <div class="vehicles">
    <div class="vehicles__header">
      <div class="vehicles__header-side">
        <Btn
          v-if="$store.getters.hasPermission(Permission.EXPORT_VEHICLES)"
          type="secondary"
          class="vehicles__btn"
          @click="downloadData"
        >
          <font-awesome-icon icon="fa-download" />
          {{ $t('download') }}
        </Btn>

        <v-menu offset="8">
          <template #activator="{ props }">
            <Btn type="primary" class="dropdown-btn" :disabled="lineInEdition" v-bind="props">
              <i class="fas fa-plus dropdown-btn__icon" aria-hidden="true"></i>
              {{ $t('addVehicle') }}
            </Btn>
          </template>
          <v-list class="dropdown-content topbar-dropdown__list">
            <v-list-item
              v-for="(item, index) in dropdownItems"
              :key="index"
              class="dropdown-item"
              @click="handleDropdownSelect(item.action)"
            >
              <template #prepend>
                <v-icon size="small" :class="item.iconClass" />
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('vehicles', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="vehiclesFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_VEHICLE)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalComment
      v-if="modalShown === ModalType.COMMENT"
      :comment="modalVehicleInitialState.comment ? modalVehicleInitialState.comment : null"
      :title-name="modalVehicleInitialState.fleet_number"
      @close="closeModal"
      @submit="setVehicleComment"
    />

    <ModalImportResource
      v-if="modalShown === ModalType.IMPORT_VEHICLE"
      resource-type="vehicles"
      @close="closeModalImport"
    />
    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_VEHICLE"
      :body="$t('confirmArchiveVehicle')"
      :title="$t('archiveVehicle')"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import ModalComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { ColumnKey, getDatagrid } from '@/pages/VehicleListPage/VehicleList.conf.js';
import { Permission } from '@/auth';

import ModalImportResource from '@/components/common/ModalImportResource.vue';
import { useVehiclesStore } from '@/store-pinia/vehicles';

const toast = useToast();

/** @enum {string} */
export const ModalType = {
  COMMENT: 'comment',
  ARCHIVE_VEHICLE: 'archive_vehicle',
  IMPORT_VEHICLE: 'import_vehicle',
};
export const validateCapacity = {
  IS_VALID_NUMBER_RANGE: value => {
    if (value === null) {
      return true;
    }
    return value >= 0 && value <= 999 && /^\d+$/.test(String(value));
  },
};

export const NEW_LINE_ID = 'new-line';

/** @enum {string} */
export const DropdownActions = {
  NEW_VEHICLE: 'newVehicle',
  IMPORT_VEHICLE: 'importVehicle',
};

export default {
  name: 'VehicleList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalComment,
    ModalImportResource,
    ModalArchiveRestore,
  },

  /** @return {{modelI18n: import('vue-i18n').IVueI18n}} */
  provide() {
    return {
      modelI18n: this.$i18n,
    };
  },

  data() {
    return {
      vehiclesStore: useVehiclesStore(),
      NEW_LINE_ID,
      ColumnKey,
      ModalType,
      Permission,

      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {?String} */
      inEditionId: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {?import('@/store-pinia/vehicles').Vehicle} */
      modalVehicleInitialState: null,
      /** @type {ModalType} */
      modalShown: '',
      /** @type {number} */
      renderedDataLength: null,
      /** @type {Array<import('@/store-pinia/vehicles').Vehicle>} */
      vehiclesFormatted: [],
      /** @type {?import('@/store-pinia/vehicles').Vehicle} */
      vehicleInEditionOriginalState: null,
      dropdownItems: [
        { label: this.$t('manually'), action: DropdownActions.NEW_VEHICLE, iconClass: 'fas fa-pen' },
        {
          label: this.$t('import'),
          action: DropdownActions.IMPORT_VEHICLE,
          iconClass: 'fa fa-arrow-circle-up',
        },
      ],
    };
  },

  computed: {
    /** @return {{[key in ColumnTypes]: (data: {apiData: import('@/store-pinia/vehicles').Vehicle}) => Object}} */
    buildCellInjectors() {
      /**
       * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
       * @return {({ name: string }) => void}
       */
      const bindActionModal = (apiDataRow, name) => () => {
        this.showModal(apiDataRow, name);
      };

      /**
       * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
       * @return {({ value: object, field: string }) => void}
       */
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, value, field);
        };

      return {
        [ColumnKey.COMMENT]: ({ apiData }) => ({ showModal: bindActionModal(apiData, ModalType.COMMENT) }),
        [ColumnKey.FLEET_NUMBER]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.LICENSE_PLATE]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.TEAM]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.CAPACITY]: ({ apiData }) => ({
          editMode: apiData.id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {Array<import('@/store-pinia/vehicles').Vehicle>*/
    vehiclesList() {
      return Object.values(this.vehiclesStore.list);
    },

    /** @return {Boolean} */
    lineInEdition() {
      return this.inEditionId != null;
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    addNewVehicle() {
      if (!this.vehiclesFormatted.find(v => v.id === NEW_LINE_ID)) {
        this.inEditionId = NEW_LINE_ID;
        this.vehiclesFormatted.unshift({
          id: NEW_LINE_ID,
          comment: '',
          fleet_number: '',
          license_plate: '',
          capacity: null,
          team_id: null,
        });
      }
    },

    downloadData() {
      window.open(`/api/v3/groups/${this.group._id}/export/vehicles`);
    },

    /** Close the opened modal */
    closeModal() {
      this.modalShown = '';
      this.modalVehicleInitialState = null;
    },

    /** Close the import modal and refresh data */
    closeModalImport() {
      this.closeModal();
      this.getDataList();
    },

    async onCreate() {
      this.loading = true;
      await this.vehiclesStore.loadList();
      this.getDataList();

      this.loading = false;
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.vehiclesFormatted.findIndex(el => el.id === id);
      if (id === NEW_LINE_ID) {
        this.vehiclesFormatted.splice(elementIndex, 1);
      } else {
        // give the element its original values back
        this.vehiclesFormatted[elementIndex] = { ...this.vehicleInEditionOriginalState };
      }
    },

    /**
     * Edit vehicle
     * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
     */
    async saveEdits(apiDataRow) {
      let valideCapacity = apiDataRow.capacity;
      if (apiDataRow.id === NEW_LINE_ID) {
        if (!this.validateCapacity(valideCapacity)) {
          if (apiDataRow.id === NEW_LINE_ID) {
            const index = this.vehiclesFormatted.findIndex(v => v.id === NEW_LINE_ID);
            if (index !== -1) {
              this.vehiclesFormatted.splice(index, 1);
            }
            this.inEditionId = null;
            return;
          }
        }
        delete apiDataRow.id;
        if (apiDataRow.fleet_number === '') {
          apiDataRow.fleet_number = this.$t('newVehicle');
        }
        await this.vehiclesStore.createVehicle(apiDataRow);
        await this.vehiclesStore.loadList();
        this.getDataList();
      } else {
        if (valideCapacity?.toString() === '') {
          valideCapacity = null;
        }
        if (!this.validateCapacity(valideCapacity)) return;
        const vehicle = { ...apiDataRow, capacity: valideCapacity };
        delete vehicle.archived;
        delete vehicle.teamName;
        delete vehicle.id;
        await this.vehiclesStore.updateVehicle(apiDataRow.id, vehicle);
      }
      this.inEditionId = null;
    },

    /** @param {import('@/store-pinia/vehicles').Vehicle['comment']} comment */
    async setVehicleComment(comment) {
      this.modalVehicleInitialState.comment = comment;
      const apiDataRow = this.vehiclesFormatted.find(
        element => element.id === this.modalVehicleInitialState.id,
      );
      this.updateValue(apiDataRow, comment, 'comment');
      // the comment is not saved immediately in case of a new vehicle or edition mode
      if (
        this.modalVehicleInitialState.id !== NEW_LINE_ID &&
        this.modalVehicleInitialState.id !== this.inEditionId
      ) {
        await this.saveEdits(apiDataRow);
      }
      this.closeModal();
    },

    /**
     * Show a modal
     * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
     * @param {ModalType} modalName
     */
    showModal(apiDataRow, modalName) {
      this.modalVehicleInitialState = { ...apiDataRow };
      this.modalShown = modalName;
    },

    /**
     * Delete vehicle
     */
    async submitModalRemove() {
      await this.vehiclesStore.deleteVehicle(this.modalVehicleInitialState.id);
      this.getDataList();
      this.closeModal();
    },

    /**
     * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
     * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
     * @param {boolean} editionMode
     */
    toggleEditionMode(apiDataRow, editionMode) {
      if (editionMode) {
        // if another edition was in progress, cancel it
        if (this.inEditionId) {
          this.resetLine(this.inEditionId);
        }
        this.inEditionId = apiDataRow.id;
        // keep a copy of the element about to be edited in case of edit cancellation
        this.vehicleInEditionOriginalState = { ...apiDataRow };
      } else {
        this.resetLine(apiDataRow.id);
        this.inEditionId = null;
        this.vehicleInEditionOriginalState = null;
      }
    },

    /**
     * Update a value - triggered upon focus lost on an EditableCell
     * @param {import('@/store-pinia/vehicles').Vehicle} apiDataRow
     * @param {string} value
     * @param {keyof import('@/store-pinia/vehicles').Vehicle} field
     */
    updateValue(apiDataRow, value, field) {
      apiDataRow[field] = value;
    },

    getDataList() {
      const data = this.vehiclesList
        .map(vehicle => {
          const newObject = vehicle;
          // handle data from V2 version
          newObject.team_id =
            vehicle.team_id && !['', 'None'].includes(vehicle.team_id) ? vehicle.team_id : null;
          newObject.comment = vehicle.comment !== 'None' ? vehicle.comment : null;
          newObject.capacity = Number(vehicle.capacity) !== 0 ? Number(vehicle.capacity) : null;
          newObject.teamName = this.$store.getters.getTeamNameById(newObject.team_id);
          return newObject;
        })
        .sort((a, b) => {
          if (a.fleet_number.toLowerCase() > b.fleet_number.toLowerCase()) return 1;
          if (a.fleet_number.toLowerCase() < b.fleet_number.toLowerCase()) return -1;
          return 0;
        });
      this.vehiclesFormatted = data;
    },
    /**
     * @param {import('@/store-pinia/vehicles').Vehicle['capacity']}
     * @return {boolean}
     */
    validateCapacity(capacity) {
      if (!validateCapacity.IS_VALID_NUMBER_RANGE(capacity)) {
        const toastId = toast.error(this.$t('validation.capacityFormat'), { position: 'bottom-right' });
        setTimeout(() => toast.dismiss(toastId), 5000);
        return false;
      }
      return true;
    },
    handleDropdownSelect(action) {
      if (action === DropdownActions.NEW_VEHICLE) {
        this.addNewVehicle();
      } else if (action === DropdownActions.IMPORT_VEHICLE) {
        this.showModal(null, ModalType.IMPORT_VEHICLE);
      }
    },
  },
};
</script>

<style lang="scss">
.vehicles {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  .dropdown-btn i {
    margin-right: 8px;
  }

  .fa-download {
    margin-right: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "addVehicle": "Ajouter un véhicule",
  "archiveVehicle": "Archiver le véhicule",
  "confirmArchiveVehicle": "Êtes-vous sûr de vouloir archiver ce véhicule ?",
  "edit": "Modifier",
  "import": "Importer",
  "manually": "Manuellement",
  "vehicles": "véhicule | véhicules",
  "validation": {
    "capacityFormat": "La capacité du véhicule doit être comprise entre 0 et 999."
  }
}
</i18n>

<i18n locale="en">
{
  "addVehicle": "Add Vehicle",
  "archiveVehicle": "Archive the vehicle",
  "confirmArchiveVehicle": "Do you want to archive the vehicle?",
  "edit": "Edit",
  "import": "Import",
  "manually": "Manually",
  "vehicles": "vehicle | vehicles",
  "validation": {
    "capacityFormat": "Vehicle capacity must be between 0 and 999."
  }
}
</i18n>
