<template>
  <div class="trip-list">
    <div class="trip-list__header">
      <!-- Date -->
      <div class="header__actions">
        <div class="header-datepicker">
          <HeaderDatepicker
            v-model:value="selectedDate"
            class="date__datepicker"
            :disabled="{ maxDate: maxDate, minDate: minDate }"
            with-labels
          />
        </div>

        <div class="header-buttons">
          <Btn
            v-if="$store.getters.hasPermission(Permission.EXPORT_TRIP_LIST)"
            type="secondary"
            @click="download"
          >
            <font-awesome-icon icon="fa-download" class="fa-icon" />
            {{ $t('download') }}
          </Btn>

          <Btn
            v-if="$store.getters.hasPermission(Permission.VIEW_MESSAGES)"
            type="primary"
            :route="{ name: GroupRoute.MESSAGE_INBOX }"
          >
            <font-awesome-icon icon="fa-paper-plane" class="fa-icon" />
            {{ $t('sendMessage') }}
          </Btn>
        </div>
      </div>
    </div>

    <!-- Body -->
    <div class="trip-list__body">
      <DataGridVuetify
        :datagrid="datagrid"
        :data="data"
        :data-filters="dataFilters"
        :tabs="tabs"
        :build-cell-injectors="buildCellInjectors"
        :merging="mergingConfig"
        :loading="loading"
        :error="error"
        :reload-on-refresh="!isSelectedDateToday || isFirstLoadOfTheDay"
      />
    </div>

    <ModalMessageNew
      v-if="modalShown === ModalType.MESSAGES"
      :recipients="[messageRecipient]"
      @close="closeModal"
    />

    <ModalStopInfo
      v-if="modalShown === ModalType.STOP_INFO"
      :date="getTripStartDate(modalData)"
      :group-id="group._id"
      :gtfs-id="modalData.gtfs[0].id"
      :trip-id="modalData.defaultId"
      :trip-updates="modalData.updates"
      @close="closeModal"
      @refresh="refresh"
    />

    <ModalTripModification
      v-if="modalShown === ModalType.MODIFY"
      :date="getTripStartDate(modalData)"
      :gtfs-id="modalData.gtfs[0].id"
      :trip-formatted-name="modalData.formatted_name"
      :trip-id="modalData.defaultId"
      :trip-updates="modalData.updates"
      @close="closeModal"
      @refresh="refresh"
    />

    <ModalTripComment
      v-if="modalShown === ModalType.COMMENT"
      :comment="getUpdate(UpdateType.COMMENT)"
      :title-name="modalData.formatted_name"
      @close="closeModal"
      @submit="setTripComment"
    >
      <template #extra-input>
        <v-checkbox id="next-days" v-model="applyOnNextDays" color="success" hide-details>
          <template #label>
            <span>
              {{ $t('nextDays') }}
            </span>
          </template>
        </v-checkbox>
      </template>
    </ModalTripComment>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import cloneDeep from 'clone-deep';
import Api, { trips, TripStatusType, UpdateType } from '@/api';
import ModalTripComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import { NO_DATA, StateMergingChildren } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import ModalMessageNew from '@/components/ui/ModalMessageNew.vue';
import { dateObjToGtfsFormat, getISODate } from '@/libs/helpers/dates';
import { Permission } from '@/auth';
import { ModalType, ScheduleRelationship } from '@/store/trips';

import ModalStopInfo from '@/components/common/ModalStopInfo.vue';
import ModalTripModification from '@/components/common/ModalTripModification/index.vue';
import { ColumnKey, getDatagrid, STATUSES } from './Trips.conf';
import { GroupRoute } from '@/libs/routing';
import { IN_PROGRESS_TAB_STATUS, TripListDataFormatter } from './TripListDataFormatterHelper';
import { InvalidResponseError } from '@/libs/api/client';

/** @enum {Array} */
const StatusCategory = {
  ALL: null, // null = no filter since we want everything
  IN_PROGRESS: [IN_PROGRESS_TAB_STATUS],
  OK: [TripStatusType.OK, TripStatusType.TRACKED],
  ANOMALIES: [TripStatusType.UNTRACKED, TripStatusType.PROBLEM],
  NO_DATA: [TripStatusType.NO_DATA],
};

/** @enum {string} */
const STATUSES_FOR_TABS = {
  [TripStatusType.OK]: 'over',
  [TripStatusType.TRACKED]: 'tracked',
  [TripStatusType.ROUTING]: 'routing',
  [TripStatusType.UNTRACKED]: 'untracked',
  [TripStatusType.PROBLEM]: 'problem',
  [TripStatusType.NO_DATA]: 'noData',
  [TripStatusType.SCHEDULED]: 'scheduled',
  [IN_PROGRESS_TAB_STATUS]: IN_PROGRESS_TAB_STATUS,
};

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

dayjs.extend(utc);
const addMonths = (input, months) => {
  const date = new Date(input);
  date.setDate(1);
  date.setMonth(date.getMonth() + months);
  date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
  return date;
};

const REFRESH_TIMEOUT = 10000;
const TIMER_INTERVAL = 1000;

export default {
  name: 'TripsListNew',

  components: {
    Btn,
    DataGridVuetify,
    HeaderDatepicker,
    ModalMessageNew,
    ModalStopInfo,
    ModalTripComment,
    ModalTripModification,
  },

  props: {
    /** @type {import('vue').Prop<{date?: string}>} */
    query: {
      default: () => ({}),
      type: Object,
    },
  },

  data() {
    return {
      // Enum bindings
      ModalType,
      Permission,
      ScheduleRelationship,
      GroupRoute,
      UpdateType,

      /** @type {Array<import('./TripListDataFormatterHelper').TripListItemFormattedV4>} */
      data: [],
      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      error: null,
      lastRequestController: null,
      loading: true,
      maxDate: undefined,
      minDate: undefined,
      /** @type {?import('./TripListDataFormatterHelper').TripListItemFormattedV4} */
      modalData: null,
      /** @type {?ModalType} */
      modalShown: null,
      /** @type {NodeJS.Timeout} */
      pollingInterval: null, // to clear setInterval used for refresh
      /** @type {{[key: string]: StateMergingChildren}} Key is "{departure_time}_{trip_id}" */
      shownMergingChildren: {},
      /** @type {Array<string>} */
      statusCategories: Object.keys(StatusCategory),
      timer: 0,
      /** @type {Array<string>} */
      devicesList: [],
      /** @type {Array<string>} */
      serviceList: [],
      /** @type {Array<string>} */
      routeList: [],
      /** @type {Array<string>} */
      tripStatusList: [],
      /** @type {boolean} */
      applyOnNextDays: false,
      isSelectedDateToday: false,
      isFirstLoadOfTheDay: false,
    };
  },

  computed: {
    /** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
    tabs() {
      const statusCategoryCopies = [...this.statusCategories];
      let defaultTab = statusCategoryCopies[1];
      // Remove "In progress" tab if not on today and set default tab on "All"
      if (!this.isSelectedDateToday) {
        statusCategoryCopies.splice(1, 1);
        defaultTab = statusCategoryCopies[0];
      }
      return statusCategoryCopies.map(category => ({
        value: category,
        name: this.$t(`statusCategories.${category}`),
        counter: null,
        dataList: [],
        filterField: ColumnKey.TAB_TRIP_STATUS,
        filterValues: StatusCategory[category]?.map(category =>
          this.$t(`tripStatus.${STATUSES_FOR_TABS[category]}`),
        ),
        icon: this.getCategoryIcon(category),
        isDefaultActive: defaultTab === category,
      }));
    },

    /** @return {{[key in ColumnKey]: (data: {apiData: import('./TripListDataFormatterHelper').TripListItemFormattedV4}) => Object}} */
    buildCellInjectors() {
      const groupId = this.group._id;

      /**
       * @param {import('./TripListDataFormatterHelper').TripListItemFormattedV4} apiDataRow
       * @param {ModalType} name
       * @return {({ name: string }) => void}
       */
      const bindActionModal = (apiDataRow, name) => () => {
        this.showModal(apiDataRow, name);
      };

      /**
       * @param {import('./TripListDataFormatterHelper').TripListItemFormattedV4} apiDataRow
       * @return {({ name: string }) => void}
       */
      const bindToggleChildren = apiDataRow => () => {
        this.toggleChildren(apiDataRow);
      };

      return {
        [ColumnKey.COMMENT]: ({ apiData }) => ({ showModal: bindActionModal(apiData, ModalType.COMMENT) }),
        [ColumnKey.FIRST_STOP]: () => ({ groupId, stopKey: 'first_stop' }),
        [ColumnKey.LAST_STOP]: () => ({ groupId, stopKey: 'last_stop' }),
        [ColumnKey.DEVICE]: () => ({ groupId, date: this.selectedDate }),
        [ColumnKey.FORMATTED_NAME]: ({ apiData }) => ({ groupId, tripId: apiData.defaultId }),
        [ColumnKey.STATUS]: ({ apiData }) => ({ toggleChildren: bindToggleChildren(apiData) }),
        [ColumnKey.TRIP_MODIFICATION]: ({ apiData }) => ({
          showModal: bindActionModal(apiData, ModalType.MODIFY),
        }),
        [ColumnKey.STOP_INFO]: ({ apiData }) => ({
          showModal: bindActionModal(apiData, ModalType.STOP_INFO),
        }),
        [ColumnKey.MESSAGES]: ({ apiData }) => ({
          showModal: bindActionModal(apiData, ModalType.MESSAGES),
        }),
      };
    },
    dataFilters() {
      return {
        [ColumnKey.STATUS]: this.tripStatusList,
        [ColumnKey.ROUTE]: this.routeList,
        [ColumnKey.DUTY]: this.serviceList,
        [ColumnKey.DEVICE]: this.devicesList,
      };
    },
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },
    mergingConfig() {
      const mergingConfig = {};
      const merging = this.group.trip_list_configuration?.merging;
      if (merging?.device) mergingConfig[ColumnKey.DEVICE] = true;
      if (merging?.gtfs) mergingConfig[ColumnKey.GTFS] = true;
      return mergingConfig;
    },
    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) {
          const date = dayjs(this.query.date).hour(23).minute(59).second(59).utc().toDate();
          return date;
        }
        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        this.$router.push({
          name: GroupRoute.TRIP_LIST,
          params: {
            groupId: this.group._id,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },

    /** @return {undefined[] | { type: string; id: string; }} */
    messageRecipient() {
      return this.modalData.devices && this.modalData.devices[0]
        ? {
            type: 'device_id',
            id: this.modalData.devices[0].id,
          }
        : [];
    },
  },

  watch: {
    'group._id': function groupId() {
      if (this.group._id) {
        this.data = [];
        this.refresh();
      }
    },
    selectedDate: {
      immediate: true,
      async handler() {
        this.isSelectedDateToday = dayjs(new Date()).isSame(this.selectedDate, 'day');
        this.setMinMaxDates();
        this.data = [];
        await this.loadGtfsDataForAPeriod();
        this.refresh();
      },
    },
    isSelectedDateToday() {
      if (this.isSelectedDateToday) this.isFirstLoadOfTheDay = true;
    },
  },

  async created() {
    // Remove status ROuting & sheduled from list since they're now merged with other status
    this.tripStatusList = Object.keys(STATUSES)
      .filter(s => ![TripStatusType.ROUTING, TripStatusType.SCHEDULED].includes(s))
      .map(s => this.$t(`tripStatus.${STATUSES[s].localeKey}`))
      .map(s => ({ value: s, label: s }));
    this.loadAllDevices();
    this.$store.dispatch('devices/getDevices');
    this.setMinMaxDates();
    this.setPollingInterval();
    await this.loadGtfsDataForAPeriod();
  },

  beforeUnmount() {
    this.checkAndAbortlastRequest();
    this.clearPollingInterval();
  },

  methods: {
    afterRefresh() {
      this.lastRequestController = null;
      this.timer = REFRESH_TIMEOUT;
      this.loading = false;
      if (this.isFirstLoadOfTheDay) {
        // interval mendatory because we need this variable to be true on first data load
        // used for reloadOnRefresh in order to correctly load datagrid filters
        setInterval(() => {
          this.isFirstLoadOfTheDay = false;
        }, 1000);
      }
      this.setPollingInterval();
    },

    beforeRefresh() {
      this.checkAndAbortlastRequest();
      this.clearPollingInterval();
      this.timer = 0;
      this.loading = true;
    },

    clearPollingInterval() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },

    checkAndAbortlastRequest() {
      if (this.lastRequestController) {
        this.lastRequestController.abort();
        this.lastRequestController = null;
      }
    },

    /**
     * Close the modal, and clear temporary data
     */
    closeModal() {
      this.modalShown = null;
      this.modalData = null;
      this.applyOnNextDays = false;
    },

    download() {
      const gtfsDate = dateObjToGtfsFormat(this.selectedDate);
      window.open(`/api/v4/groups/${this.group._id}/export/trips?date=${gtfsDate}`);
    },

    getCategoryIcon(category) {
      switch (category) {
        case 'OK':
          return 'fa:fas fa-check';
        case 'ANOMALIES':
          return 'fa:fas fa-exclamation-circle';
        case 'NO_DATA':
          return 'fa:fas fa-question-circle';
        case 'IN_PROGRESS':
          return 'fa:fas fa-hourglass-start';
        default:
          return '';
      }
    },

    /**
     * @param {import('./TripListDataFormatterHelper').TripListItemFormattedV4} apiDataRow
     * @return {string}
     */
    getTripStartDate(apiDataRow) {
      return apiDataRow.departure_time
        ? dateObjToGtfsFormat(new Date(apiDataRow.departure_time * 1000))
        : dateObjToGtfsFormat(this.selectedDate);
    },

    async refresh() {
      this.beforeRefresh();

      this.lastRequestController = new AbortController();
      const { signal } = this.lastRequestController;

      try {
        const data = await trips.getTripListV4(this.group._id, dateObjToGtfsFormat(this.selectedDate), {
          signal,
        });
        this.error = null;

        /** @type {Array<import('./TripListDataFormatterHelper').TripListItemFormattedV4>} */
        const dataUnformated = data.reduce((acc, data, index) => {
          const rowData = /** @type {import('./TripListDataFormatterHelper').TripListItemFormattedV4} */ (
            data
          );

          const key = `${rowData.departure_time}_${rowData.defaultId}`;
          if (rowData.trips?.length > 0) {
            rowData.status = this.shownMergingChildren[key] || StateMergingChildren.HIDDEN;
            rowData.displayChildren = this.shownMergingChildren[key] || StateMergingChildren.HIDDEN;
          }
          // Merge old tripStatus calculated in backend
          if (rowData.status === TripStatusType.SCHEDULED) rowData.status = TripStatusType.NO_DATA;
          if (rowData.status === TripStatusType.ROUTING) rowData.status = TripStatusType.TRACKED;

          // generate unique id key in case of multiple gtfs or devices with same trip id
          const gtfsId = rowData.gtfs[0]?.id || '';
          const deviceId = rowData.devices?.[0]?.id || '';
          rowData.defaultId = rowData.id;
          rowData.id = `${rowData.id}-${gtfsId}-${deviceId}-${index}`;
          acc.push(rowData);
          return acc;
        }, []);

        /** @type {Array<import('./TripListDataFormatterHelper').TripListItemFormattedV4>} */
        const dataFormatted = [];
        dataUnformated.forEach(trip => {
          const newObj = TripListDataFormatter.formatData(trip, this.$store.getters.group.tz);

          // Get formatted trip children
          if (trip.trips && trip.trips.length > 0) {
            newObj.childrenItems = trip.trips.map(childTrip => {
              const formattedChild = TripListDataFormatter.formatData(
                childTrip,
                this.$store.getters.group.tz,
              );
              formattedChild.defaultId = trip.defaultId;
              return formattedChild;
            });
          }

          dataFormatted.push(newObj);
        });
        this.data = dataFormatted;
        this.afterRefresh();
      } catch (e) {
        if (e instanceof InvalidResponseError) {
          this.error = this.$t('serverError');
          this.afterRefresh();
        }
      }
    },

    /**
     * @param {UpdateType} type
     * @return {string | boolean | Array<import('@/api').StopInfo> | Array<number> | number | null} // could be type of any TripUpdateV2 field
     */
    getUpdate(type) {
      return this.modalData.updates?.[type] ?? null;
    },

    setPollingInterval() {
      this.clearPollingInterval();

      this.pollingInterval = setInterval(() => {
        if (this.timer <= TIMER_INTERVAL) {
          if (this.isSelectedDateToday) {
            this.refresh();
          }
        } else {
          this.timer -= TIMER_INTERVAL;
        }
      }, TIMER_INTERVAL);
    },

    setMinMaxDates() {
      if (!this.$store.getters.hasPermission(Permission.VIEW_OVER_A_WEEK_HISTORY)) {
        this.minDate = new Date(new Date().getTime() - 604800000); // 7 days before
        this.maxDate = new Date(new Date().getTime() + 604800000); // 7 days after
      } else {
        this.minDate = addMonths(new Date(), -13); // 13 months before
        this.maxDate = new Date(new Date().getTime() + 604800000); // 7 days after
      }
    },

    /**
     * Set a comment on a trip.
     * @param {string} comment
     */
    async setTripComment(comment) {
      const updatedComment = comment.length > 0 ? comment : null;

      const tripUpdates = {
        query: {
          gtfs_id: this.modalData.gtfs[0].id,
          trip_id: this.modalData.defaultId,
          start_date: this.getTripStartDate(this.modalData),
        },
        body: {
          comment: updatedComment,
          delay: this.getUpdate(UpdateType.DELAY) ?? 0,
          is_canceled: this.getUpdate(UpdateType.TRIP_CANCELED) ?? false,
          skipped_stop_time_seqs: this.getUpdate(UpdateType.DO_NOT_SERVE) ?? [],
          stop_infos: this.getUpdate(UpdateType.STOP_INFO) || [],
        },
        many: this.applyOnNextDays,
      };

      await this.$store.dispatch('trips/updateTrip', tripUpdates);

      this.refresh();
      this.closeModal();
    },

    /**
     * Show a modal
     * @param {import('./TripListDataFormatterHelper').TripListItemFormattedV4} apiDataRow
     * @param {ModalType} modalName
     */
    showModal(apiDataRow, modalName) {
      this.modalData = { ...apiDataRow };
      this.modalShown = modalName;
    },

    /** @param {import('./TripListDataFormatterHelper').TripListItemFormattedV4} apiDataRow */
    toggleChildren(apiDataRow) {
      const key = `${apiDataRow.departure_time}_${apiDataRow.defaultId}`;
      this.shownMergingChildren[key] =
        this.shownMergingChildren[key] === StateMergingChildren.SHOWN
          ? StateMergingChildren.HIDDEN
          : StateMergingChildren.SHOWN;
      apiDataRow.status = this.shownMergingChildren[key];
      apiDataRow.displayChildren = this.shownMergingChildren[key];
    },

    async loadAllDevices() {
      const fetchDevices = (await Api.devices.getDevices(this.group._id)) || [];
      // as API works, device is either specified with his name, or device_id
      const devices = fetchDevices.map(device => device.name || device.device_id);
      devices.unshift(NO_DATA);
      this.devicesList = devices.map(value => ({ value, label: value }));
    },

    /**
     * Calculate start & end date in TS & fetch all gtfsIds used in this period
     */
    async getGtfsIdListForPeriod() {
      // calc end day ts
      const endDayTs = cloneDeep(this.selectedDate.getTime() / 1000);
      // calc start day ts
      const startDate = cloneDeep(this.selectedDate);
      startDate.setHours(0, 0, 0);
      const startDayTs = startDate.getTime() / 1000;
      // get all GtfsIds in this period
      const gtfsList = (
        await this.$store.dispatch('gtfs/getGtfsPublicationsIn', {
          fromTs: startDayTs,
          toTs: endDayTs,
        })
      ).map(p => p.current_file);
      return gtfsList;
    },

    /**
     * Load gtfs data based on potential multiple gtfsIds
     */
    async loadGtfsDataForAPeriod() {
      const gtfsList = await this.getGtfsIdListForPeriod();

      if (gtfsList) {
        const routesShortNameSet = new Set();
        const blockIdSet = new Set();

        // eslint-disable-next-line no-restricted-syntax
        for (const gtfsId of gtfsList) {
          const r = await this.$store.dispatch('gtfs/getRoutesMap', { gtfsId });
          if (r) {
            Object.keys(r).forEach(routeId => {
              routesShortNameSet.add(r[routeId].route_short_name);
            });
          }
          const s = await this.$store.dispatch('gtfs/getTripsMap', { gtfsId });
          if (s) {
            Object.keys(s).forEach(tripId => {
              const blockId = s[tripId]?.block_id;
              if (blockId) blockIdSet.add(blockId);
            });
          }
        }
        this.routeList = Array.from(routesShortNameSet).map(value => ({ value, label: value }));
        this.serviceList = Array.from(blockIdSet).map(value => ({ value, label: value }));
        this.serviceList.unshift(NO_DATA);
      }
    },
  },
};
</script>

<style lang="scss">
.trip-list {
  min-width: 100%;
  padding: $view-standard-padding;
  font-size: 14px;

  .header__timer {
    padding: 0.5em;
    border-radius: 50%;
    background: $background;
    cursor: pointer;
  }

  .header__actions {
    display: flex;
    justify-content: space-between;

    .header-buttons {
      display: flex;
    }

    .header-datepicker {
      flex: 1;
      justify-content: left;

      .datepicker {
        padding: 0.25em 0.5em 0.25em 0.8em;
      }
    }
  }

  &__header {
    padding-bottom: 12px;

    .ui-btn {
      padding: 0 15px;
      line-height: 40px;

      .fa-icon {
        margin-right: 10px;
      }
    }
  }

  .route-badge {
    // override RouteBadge component style for overflow color
    display: inline-block;
    max-width: 100%;
    line-height: normal;
  }

  .fa-triangle-exclamation {
    color: unset; // override old trip-list style
  }
}
</style>

<i18n locale="fr">
{
  "statusCategories": {
    "ALL": "Toutes",
    "OK": "Ok",
    "ANOMALIES": "Anomalies",
    "NO_DATA": "Pas de données",
    "IN_PROGRESS": "En cours"
  },
  "nextDays": "Appliquer aux jours suivants",
  "resultCount": "Résultat: {count} course | Résultat: {count} courses",
  "serverError": "Une erreur est survenue, nous allons réessayer de mettre à jour vos données.",
  "sendMessage": "Envoyer un message"
}
</i18n>

<i18n locale="en">
{
  "statusCategories": {
    "ALL": "All",
    "OK": "Ok",
    "ANOMALIES": "Anomalies",
    "NO_DATA": "No data",
    "IN_PROGRESS": "In progress"
  },
  "nextDays": "Apply to the next days",
  "resultCount": "Result: {count} trip | Result: {count} trips",
  "serverError": "An error has occurred, we will try to update your data again.",
  "sendMessage": "Send a message"
}
</i18n>
