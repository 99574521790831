<template>
  <div class="layout">
    <NavBarLeft />
    <div class="layout__right">
      <NavBarTop />
      <div class="layout__bottom">
        <router-view v-if="$store.getters.group?._id" />
        <ModalUrgency
          v-if="displayUrgenciesModal"
          :urgencies="newUrgencies"
          @handleAlerts="acknowledgeUrgencies"
          @close="urgenciesModalTimeout = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Permission } from '@/auth';
import { GroupRoute } from '@/libs/routing';
import NavBarLeft from '@/components/layout/NavBarLeft.vue';
import NavBarTop from '@/components/layout/NavBarTop/index.vue';
import ModalUrgency from '@/components/common/ModalUrgency.vue';

export default {
  name: 'Group',

  components: {
    NavBarLeft,
    NavBarTop,
    ModalUrgency,
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    urgenciesModalTimeout: false,
  }),

  computed: {
    /** @return {Array<import('@/store/urgencies').Urgency>}*/
    newUrgencies() {
      return this.$store.getters['urgencies/getNewUrgencies'];
    },

    acknowledgedUrgencies() {
      return this.$store.getters['urgencies/getAcknowledgedUrgencies'];
    },

    acknowledgedUrgenciesIds() {
      return this.acknowledgedUrgencies.map(u => u.urgency_id);
    },

    /** @return {boolean} */
    displayUrgenciesModal() {
      return (
        this.newUrgencies.length > 0 &&
        this.$store.getters.hasPermission(Permission.HANDLE_URGENCIES) &&
        this.$route.name !== 'urgencies' &&
        this.urgenciesModalTimeout === true
      );
    },
  },

  watch: {
    newUrgencies: {
      handler(newUrgencies) {
        if (newUrgencies.length > 0) {
          if (this.$route.name === GroupRoute.URGENCIES) {
            // If the user is on the urgencies page and there are new urgencies, acknowledge them instantly
            this.acknowledgeUrgencies();
          } else {
            // Timeout to avoid ephemeral apparition of the modal if some other user is already on the urgencies page
            setTimeout(() => {
              const newUrgenciesAfterTimeout = this.$store.getters['urgencies/getNewUrgencies'];
              if (newUrgenciesAfterTimeout.length > 0) {
                this.urgenciesModalTimeout = true;
              }
            }, 1000);
          }
        }
      },
    },
  },

  methods: {
    async acknowledgeUrgencies() {
      this.urgenciesModalTimeout = false;
      const newUrgencies = [...this.newUrgencies];
      await Promise.all(
        newUrgencies.map(u => {
          if (this.acknowledgedUrgenciesIds.includes(u.urgency_id)) return;
          this.$store.dispatch('urgencies/acknowledgeUrgency', {
            groupId: this.groupId,
            urgencyId: u.urgency_id,
          });
        }),
      );
      if (this.$route.name !== 'urgencies') this.$router.push({ name: 'urgencies' });
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * layout
 *   __right
 *   __bottom
 */
.layout {
  display: flex;
  height: 100%;

  &__right {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    height: 100%;
  }

  &__bottom {
    flex: 1;
    overflow: auto;
  }
}
</style>
