<script setup>
import { computed, onMounted, ref } from 'vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import OutboxModalDetails from '@/pages/MessageListPage/OutboxModalDetails.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalMessageNew, { RecipientType } from '@/components/ui/ModalMessageNew.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

import { ColumnKey, getDatagrid } from '@/pages/MessageListPage/Sent.conf.js.js';
import { useMessage } from '@/use/use-message';
import { BoxDirection } from '@/store/messages';

const {
  isLoading,
  messageListType,
  shownMessages,
  modalMessageArchiveShown,
  modalMessageDetail,
  modalMessageNew,
  getMessages,
  showModalMessageDetail,
  handleSent,
  showModalMessageNew,
  multiArchive,
  closeModalMessageNew,
  submitModalMessageArchive,
} = useMessage();

/** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
const datagrid = getDatagrid();
/** @type {import('vue').Ref<number>} */
const renderedDataLength = ref();

/** @return {Object} */
const buildCellInjectors = computed(() => {
  const bindClickHandler = apiData => () => showModalMessageDetail(apiData);
  return {
    [ColumnKey.CONTENT]: ({ apiData }) => ({
      click: bindClickHandler(apiData),
    }),
  };
});

/**
 * @param {import('@/use/use-message').FormattedMessage} message
 */
function copyMessage(message) {
  const recipients = message.recipients.map(({ senderId }) => ({
    id: senderId,
    type: RecipientType.DEVICE,
  }));

  showModalMessageNew(recipients, {
    content: message.content,
    urgent: message.urgent,
  });
}

onMounted(async () => {
  messageListType.value = BoxDirection.OUTBOX;
  await getMessages();
});
</script>

<template>
  <div class="inbox-message">
    <div class="inbox-message__header">
      <Btn type="primary" @click="showModalMessageNew()">
        <i class="fas fa-paper-plane" aria-hidden="true"></i>
        <span class="btn-text">{{ $t('message.new') }}</span>
      </Btn>
    </div>
    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('sentMessages', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="shownMessages"
      :loading="isLoading"
      :datagrid="datagrid"
    >
      <template #actions="propsAction">
        <ActionCell
          :actions="['copy', 'archive']"
          :object="propsAction.object"
          @copy="dataLine => copyMessage(dataLine)"
          @archive="dataLine => multiArchive([dataLine])"
        />
      </template>
      <template #selectedItemsActions="propsAction">
        <Btn
          type="secondary"
          :smaller="true"
          :title="$t('archive')"
          @click="multiArchive(propsAction.objects)"
        >
          <span class="action-cell__btn">
            <font-awesome-icon icon="fa-box-archive" class="mr-2" />
            {{ $t('archive') }}
          </span>
        </Btn>
      </template>
    </DataGridVuetify>

    <ModalMessageNew
      v-if="modalMessageNew.shown"
      :recipients="modalMessageNew.recipients"
      :message="modalMessageNew.message"
      @sent="handleSent"
      @close="closeModalMessageNew"
    />

    <OutboxModalDetails
      v-if="modalMessageDetail.shown"
      :message="modalMessageDetail.message"
      @close="modalMessageDetail.shown = false"
    />

    <ModalArchiveRestore
      v-if="modalMessageArchiveShown"
      :title="$t('archive')"
      :body="$t('message.confirmArchive')"
      @close="modalMessageArchiveShown = false"
      @submit="submitModalMessageArchive"
    />
  </div>
</template>

<style lang="scss" scoped>
.inbox-message {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  height: 100%;
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;

    .btn-text {
      margin-left: 10px;
    }
  }
}
</style>

<i18n locale="fr">
{
  "sentMessages": "message envoyé | messages envoyés"
}
</i18n>

<i18n locale="en">
{
  "sentMessages": "sent message | sent messages"
}
</i18n>
