<template>
  <div class="user-list">
    <div v-if="isAdmin" class="header">
      <Btn type="primary" :disabled="lineInEdition" class="add-user" @click="addUserForm">
        <i class="fas fa-plus" aria-hidden="true"></i>
        <span class="add-user-text">{{ $t('addUser') }}</span>
      </Btn>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('users', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="usersFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="lineInEdition"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <template v-if="propsAction.object._id === NEW_LINE_ID">
          <ActionCell
            edit-mode
            :actions="[]"
            :object="propsAction.object"
            @save="saveEdits"
            @switchOffEditionMode="cancelEdition"
          />
        </template>
        <ActionCell
          v-else-if="isAdmin && propsAction.object.user_id !== user.id"
          :actions="['archive']"
          :object="propsAction.object"
          @archive="showArchiveModal"
        />
      </template>
    </DataGridVuetify>

    <ModalArchiveRestore
      v-if="modalShown === 'archive_user'"
      :title="$t('deleteUser')"
      :body="$t('confirmDeleteUser', [selectedUserId])"
      @close="closeModal()"
      @submit="submitModalRemove"
    />
  </div>
</template>

<script>
import api from '@/api';
import { Role, Permission } from '@/auth';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import { ColumnKey, getDatagrid } from '@/pages/UserListPage/UserList.conf.js';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

/** @enum {string} */
export const ModalType = {
  ARCHIVE_USER: 'archive_user',
};

export const NEW_LINE_ID = 'new-line';

export default {
  name: 'UserList',

  components: {
    Btn,
    DataGridVuetify,
    ModalArchiveRestore,
    ActionCell,
  },

  data() {
    return {
      NEW_LINE_ID,
      ColumnKey,
      Permission,
      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {?String} */
      inEditionId: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {string} */
      modalShown: '',
      /** @type {string} */
      selectedUserId: '',
      /** @type {number} */
      renderedDataLength: null,
      userModal: {
        /** @type {string} */
        email: '',
        /** @type {string} */
        role: 'operator',
      },
      /** @type {Array<import('@/api').UserRole>} */
      userList: [],
      /** @type {Array<UserFormatted>} */
      usersFormatted: [],
    };
  },

  computed: {
    buildCellInjectors() {
      /**
       * @param {UserFormatted} apiDataRow
       * @return {({ value: object, field: string }) => void}
       */
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          if (apiDataRow[field] !== value) {
            this.updateValue(apiDataRow, value, field);
          }
        };

      return {
        [ColumnKey.USER_NAME]: ({ apiData }) => ({
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.ROLE]: ({ apiData }) => ({
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.TEAMS]: ({ apiData }) => ({
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },

    /** @return {Boolean} */
    isAdmin() {
      return this.$store.getters.hasPermission(Permission.ADMIN_USERS);
    },

    /** @return {Boolean} */
    lineInEdition() {
      return this.inEditionId != null;
    },

    /** @return {import('@/store').User} */
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    userList() {
      this.formatUsers();
      this.loading = false;
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    /** Close the opened modal */
    closeModal() {
      this.modalShown = '';
      this.selectedUserId = '';
    },

    async onCreate() {
      this.loading = true;
      this.$store.dispatch('loadTeams');
      this.getData();
    },

    addUserForm() {
      if (!this.usersFormatted.find(v => v._id === NEW_LINE_ID)) {
        this.inEditionId = NEW_LINE_ID;
        this.usersFormatted.unshift({
          _id: NEW_LINE_ID,
          user_id: '',
          role: Role.OPERATION_MANAGER,
          creationDate: '',
          teams: [],
        });
      }
    },

    /** Delete role */
    async submitModalRemove() {
      await api.deleteRole(this.$store.getters.group._id, this.selectedUserId);
      const findIndex = this.userList.findIndex(user => this.selectedUserId === user.user_id);
      this.usersFormatted.splice(findIndex, 1);
      this.closeModal();
    },

    /**
     * Compare array
     * @param {Array<string>} arr1
     * @param {Array<string>} arr2
     */
    arraysCompare(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      return arr1.every((v, i) => v === arr2[i]);
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.usersFormatted.findIndex(el => el._id === id);
      if (id === NEW_LINE_ID) {
        this.usersFormatted.splice(elementIndex, 1);
      }
    },

    async saveEdits(apiDataRow) {
      if (apiDataRow.user_id === '') return;
      this.resetLine(apiDataRow._id);
      delete apiDataRow._id;
      delete apiDataRow.creationDate;
      apiDataRow.email = apiDataRow.user_id.trim();
      delete apiDataRow.user_id;
      await api.addRoles(this.$store.getters.group._id, apiDataRow);

      this.inEditionId = null;
      this.getData();
    },

    /**
     * Update a value - triggered upon focus lost on an Cell
     * @param {Object} apiDataRow
     * @param {string} value
     * @param {String} field
     */
    async updateValue(apiDataRow, value, field) {
      const oldValue = apiDataRow[field] || [];
      if (field !== 'teams' || (field === 'teams' && !this.arraysCompare(oldValue, value))) {
        apiDataRow[field] = value;

        if (apiDataRow._id !== NEW_LINE_ID) {
          await api.updateRole(this.$store.getters.group.group_id, { ...apiDataRow });
        }
      }
    },

    formatUsers() {
      this.usersFormatted = this.userList.map(user => {
        const /** @type {UserFormatted} */ newUser = user;
        newUser.teams = user.teams || [];
        newUser.creationDate = new Date(parseInt(user._id.substr(0, 8), 16) * 1000);
        return newUser;
      });
    },
    cancelEdition(apiDataRow) {
      this.inEditionId = null;
      this.resetLine(apiDataRow._id);
    },

    showArchiveModal(apiDataRow) {
      if (apiDataRow) this.selectedUserId = apiDataRow.user_id;
      this.modalShown = ModalType.ARCHIVE_USER;
    },

    async getData() {
      const data = await api.getRoles(this.$store.getters.group._id);
      if (data)
        data.sort((a, b) => {
          if (a.user_id.toLowerCase() > b.user_id.toLowerCase()) return 1;
          if (a.user_id.toLowerCase() < b.user_id.toLowerCase()) return -1;
          return 0;
        });
      this.userList = data;
    },
  },
};
/**
 * @typedef {Object} UserFormatted
 * @property {Object} [creationDate]
 * @property {Role} role
 * @property {Array<string>} [teams]
 * @property {String} user_id
 * @property {String} _id
 */
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;

  .add-user {
    display: flex;
    align-items: center;
    height: 40px;

    .add-user-text {
      margin-left: 10px;
    }
  }
}

.user-list {
  padding: $view-standard-padding;
}
</style>

<i18n locale="fr">
{
  "confirmDeleteUser": "Voulez-vous archiver l'utilisateur {0} ?",
  "deleteUser": "Archiver l'utilisateur",
  "email": "Email",
  "users": "utilisateur | utilisateurs",
  "addUser": "Ajouter un nouvel utilisateur",
  "validate": "Valider",
  "selectColumns": "Colonnes"
}
</i18n>

<i18n locale="en">
{
  "confirmDeleteUser": "Do you want to archive {0} user ?",
  "deleteUser": "Archive user",
  "addUser": "Add New user",
  "email": "Email",
  "users": "user | users",
  "validate": "Validate",
  "selectColumns": "Columns"
}
</i18n>
